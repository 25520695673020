import styled, { css } from 'styled-components'

import Container from '../../Container'
import { StyledNavigationLink } from '../../Links/NavigationLink/styles'
import { transition } from '../../../common/mixins'
import media, { customMedia } from '../../../common/MediaQueries'

const StyledAimTeaserImg = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;

  .gatsby-image-wrapper {
    height: 100%;
    ${transition}
  }
  .gatsby-image-wrapper div {
    padding-bottom: 72% !important;
  }
`

const StyledAimTeaser = styled(Container)`
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;

  @media ${media.phone} {
    flex-direction: row;
    height: 100%;

    &:hover {
      ${StyledNavigationLink} {
        text-decoration: underline;
      }

      ${StyledAimTeaserImg} {
        .gatsby-image-wrapper {
          transform: scale3d(1.05, 1.05, 1.05);
        }
      }
    }
  }
`

const StyledAimTitle = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.navyBlue};
  margin-bottom: 0.5rem;

  @media ${media.phone} {
    margin-bottom: 2.5rem;
    margin-right: 3.25rem;
  }
`

const StyledAimTeaserContent = styled(Container)`
  display: flex;
  padding: 1.25rem 1rem;
  align-items: flex-start;
  min-height: 15.125rem;

  @media ${media.phone} {
    padding: 4.5rem 3.125rem 4rem 5.25rem;
    justify-content: flex-start;
    min-height: 9.125rem;
    flex-grow: 1;
    width: 57%;
    min-height: auto;
  }
`

const StyledAimTeaserContentInner = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;

  br {
    display: none;
  }

  @media ${media.tablet} {
    justify-content: flex-start;
    br {
      display: block;
    }
  }
`

const StyledAimTeaserMedia = styled.div`
  position: relative;

  @media ${media.phone} {
    width: 43%;
  }
`

const StyledAimTeaserAction = styled.div`
  transform: translateY(calc(-4.375rem));

  @media ${media.phone} {
    transform: translateY(0);
  }
`

const StyledAimTeaserDetails = styled.div`
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  ${transition}

  @media ${media.phone} {
    display: none;
  }
`

const StyledAimTeaserWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  ${({ isCollapsed }) =>
    !isCollapsed &&
    css`
      ${StyledAimTeaserDetails} {
        opacity: 1;
        max-height: 200vh;
      }
    `}
`

const StyledAimTeaserActionMobile = styled.div`
  @media ${media.phone} {
    display: none;
  }
`

const StyledAimTeaserActionDesktop = styled.div`
  @media ${customMedia.maxPhone} {
    display: none;
  }
`

const StyledAimTeaserIconBox = styled.div`
  width: 100%;
  margin-bottom: 1.75rem;

  @media ${media.phone} {
    margin-bottom: 2.5rem;
  }
`

const StyledAimTeaserIcon = styled.img`
  width: 3.3125rem;
  height: 3.3125rem;

  @media ${media.phone} {
    width: 6.0625rem;
    height: 6.0625rem;
  }
`

export {
  StyledAimTeaser,
  StyledAimTitle,
  StyledAimTeaserContent,
  StyledAimTeaserContentInner,
  StyledAimTeaserMedia,
  StyledAimTeaserImg,
  StyledAimTeaserAction,
  StyledAimTeaserDetails,
  StyledAimTeaserWrapper,
  StyledAimTeaserActionMobile,
  StyledAimTeaserActionDesktop,
  StyledAimTeaserIconBox,
  StyledAimTeaserIcon,
}
