import styled from 'styled-components'

export const StyledHeading = styled.div`
  position: relative;
  padding-bottom: 1.75rem;
  text-align: center;

  &::after {
    position: absolute;
    display: block;
    content: '';
    width: 10.625rem;
    height: 0.125rem;
    background-color: currentColor;
    bottom: 0;
    left: calc(50% - (10.625rem / 2));
  }
`

export const StyledHeadingTitle = styled.div``

export const StyledHeadingSubtitle = styled.div`
  margin-top: 1rem;
`
