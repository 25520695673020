import styled from 'styled-components'

export const VideoExternalStyled = styled.iframe`
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
`
