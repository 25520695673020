import React, { useState } from 'react'
import PropTypes from 'prop-types'
import VisibilitySensor from 'react-visibility-sensor'

import SimpleSection from '../SimpleSection'
import {
  StyledSimpleSectionPeople,
  StyledSimpleSectionPeopleCol,
} from './styles'
import PeopleLeftIcon from '../../../Icons/PeopleLeftIcon'
import PeopleRightIcon from '../../../Icons/PeopleRightIcon'

const PeopleSection = ({ title, titleColor, description, count }) => {
  const [isVisible, setIsVisible] = useState(false)

  const onVisibilityChange = (visibility) => {
    if (visibility) {
      setIsVisible(true)
    }
  }

  return (
    <>
      <SimpleSection {...{ title, titleColor, description, count }} />
      <StyledSimpleSectionPeople>
        <StyledSimpleSectionPeopleCol>
          <PeopleRightIcon />
        </StyledSimpleSectionPeopleCol>
          <VisibilitySensor
            onChange={onVisibilityChange}
            offset={{
              top: 200,
            }}
            delayedCall
            resizeCheck
          >
            <StyledSimpleSectionPeopleCol isMain {...{ isVisible }}>
              <PeopleLeftIcon />
            </StyledSimpleSectionPeopleCol>
          </VisibilitySensor>
        <StyledSimpleSectionPeopleCol>
          <PeopleLeftIcon />
        </StyledSimpleSectionPeopleCol>
      </StyledSimpleSectionPeople>
    </>
  )
}

export default PeopleSection

SimpleSection.propTypes = {
  title: PropTypes.string,
  titleColor: PropTypes.string,
  description: PropTypes.string,
  count: PropTypes.string,
}
