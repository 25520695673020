import React from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import LazyLoad from 'react-lazyload'
import { useStaticQuery, graphql } from 'gatsby'

import {
  StyledQA,
  StyledQAHead,
  StyledQASubhead,
  StyledQAMedia,
  StyledQADesc,
  StyledQAPlayer,
  StyledQAAction,
  StyledQAIframe,
} from './styles'
import { Heading2, Heading15, Heading16 } from '../Typography'
import DownloadLink from '../Links/DownloadLink'
import CeoStatatementFile from '../../files/ses-2021-ceo-statement.pdf'

const QA = () => {
  const {
    images: { edges: qa },
  } = useStaticQuery(
    graphql`
      query {
        images: allImageSharp(
          filter: { original: { src: { regex: "/qa/" } } }
          sort: { fields: original___src }
        ) {
          edges {
            node {
              id
              fluid(maxWidth: 200, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    `,
  )

  return (
    <StyledQA>
      <StyledQAHead>
        <Heading2
          color="teal"
          fontSize={['6.125rem', '8.25rem']}
          lineHeight="1"
          fontWeight="ultra"
        >
          Q&amp;A
        </Heading2>
      </StyledQAHead>
      <StyledQASubhead>
        <Heading15 color="white" fontWeight="bold">
          with Ian Cain, CEO
        </Heading15>
      </StyledQASubhead>
      <StyledQAMedia>
        <Img fluid={qa[0].node.fluid} />
      </StyledQAMedia>
      <StyledQADesc>
        <Heading16 color="white" fontWeight="book" textAlign="center">
          Ian joined SES Water in February 2020 and in this podcast we hear from him.
        </Heading16>
      </StyledQADesc>
      <StyledQAPlayer>
        <LazyLoad offset={400}>
          <StyledQAIframe
            loading="lazy"
            scrolling="no"
            frameborder="no"
            height="166"
            allow="autoplay"
            title="SES Water"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1071166045&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
          />
        </LazyLoad>
      </StyledQAPlayer>
      <StyledQAAction>
        <DownloadLink
          url={CeoStatatementFile}
          label="Download Chief Executive Officer's Statement"
        />
      </StyledQAAction>
    </StyledQA>
  )
}

export default QA
