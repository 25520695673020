import React, { useState } from 'react'
import VisibilitySensor from 'react-visibility-sensor'
import CountUp from 'react-countup'

import {
  StyledCirclesSectionColBox,
  StyledCirclesSectionCol,
  StyledCirclesSectionDesc,
  StyledCirclesSectionIcon,
  StyledCirclesSectionNumber,
} from './styles'
import CirclesIcon from '../../../Icons/CirclesIcon'
import { Typo13, Heading9 } from '../../../Typography'

const CirclesSection = ({ count }) => {
  const [isVisible, setIsVisible] = useState(false)

  const onVisibilityChange = (visibility) => {
    if (visibility) {
      setIsVisible(true)
    }
  }

  const easingFn = (t, b, c, d) => {
    const ts = (t /= d) * t
    const tc = ts * t
    return b + c * (tc + -1 * ts + 3 * t)
  }

  return (
    <VisibilitySensor
      onChange={onVisibilityChange}
      offset={{
        top: 100,
      }}
      delayedCall
      resizeCheck
    >
      <StyledCirclesSectionColBox>
        <StyledCirclesSectionCol>
          <StyledCirclesSectionIcon {...{ isVisible }}>
            <CirclesIcon />
            <StyledCirclesSectionNumber>
              <Heading9 fontWeight="bold" color="orange">
                <CountUp
                  start={1}
                  end={isVisible ? 8 : 1}
                  duration={5.7}
                  {...{ easingFn }}
                />
              </Heading9>
            </StyledCirclesSectionNumber>
          </StyledCirclesSectionIcon>
        </StyledCirclesSectionCol>
        <StyledCirclesSectionCol>
          <StyledCirclesSectionDesc>
            <Typo13
              as="h3"
              fontWeight="bold"
              color="valhalla"
              textAlign={['center', 'left']}
            >
              Water treatment works
            </Typo13>
          </StyledCirclesSectionDesc>
        </StyledCirclesSectionCol>
      </StyledCirclesSectionColBox>
    </VisibilitySensor>
  )
}

export default CirclesSection
