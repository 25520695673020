import PicInvestment from '../../assets/pic-investment.svg'
import PicEnergy from '../../assets/pic-energy.svg'
import PicEmployees from '../../assets/pic-employees.svg'
import PicFinancing from '../../assets/pic-financing.svg'
import PicTax from '../../assets/pic-tax.svg'

export const values = [
  {
    id: 'financing',
    title: 'Financing',
    desc:
      'This cost includes paying interest on the money we borrow through overdrafts and loans to improve the service we provide',
    value: '6p',
    position: {
      top: ['', '', '6rem'],
      left: ['', '', '-12rem', '0'],
    },
    icon: {
      img: PicFinancing,
      position: {
        top: ['2.75rem', '4.25rem', '4.675rem', '7rem'],
        left: ['1.5rem', '2.5rem', '2.75rem', '4rem'],
      },
    },
  },
  {
    id: 'employees',
    title: 'Employees',
    desc:
      'Paying our people and investing in the development of our workforce is a significant cost',
    value: '8p',
    position: {
      top: ['', '', '60%', '55%'],
      left: ['', '', '-13rem', '-1rem'],
    },
    icon: {
      img: PicEmployees,
      position: {
        top: ['9.75rem', '15.5rem', '18rem', '26rem'],
        left: ['0', '0', '0'],
      },
    },
  },
  {
    id: 'energy',
    title: 'Energy and other costs',
    desc:
      'Pumping, treating and distributing water is a complex process that also uses a lot of energy, particularly as 85% of the water we supply comes from deep under the ground',
    value: '15p',
    position: {
      right: ['', '', '-7rem', '13rem'],
      bottom: ['', '', '-4rem', '-2rem'],
    },
    icon: {
      img: PicEnergy,
      position: {
        top: ['16.5rem', '26rem', '30.5rem', '44rem'],
        left: '45%',
      },
    },
  },
  {
    id: 'tax',
    title: 'Tax, rates and licences',
    desc:
      'As well as corporation tax, we also contribute to public finances through business rates and national insurance, as well as paying environmental taxes and other duties such as water abstraction charges',
    value: '4p',
    position: {
      top: '0',
      left: ['', '', '-12rem', '5rem'],
    },
    icon: {
      img: PicTax,
      position: {
        top: '0',
        left: ['6.125rem', '9.675rem', '11.125rem', '16rem'],
      },
    },
  },
  {
    id: 'investment',
    title: 'Investment',
    desc:
      'We have a strong track record in making the right investment decisions for the long-term which means our customers today benefit from a more robust service',
    value: '17p',
    position: {
      top: ['', '', '40%'],
      right: ['', '', '-14rem', '-1rem'],
    },
    icon: {
      img: PicInvestment,
      position: {
        top: ['5rem', '7rem', '7rem'],
        right: ['0.5rem', '1.5rem', '2rem', '4rem'],
      },
    },
  },
]
