import React, { useState } from 'react'
import PropTypes from 'prop-types'
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'

import { StatisticsStyled } from './styles'
import { Heading9 } from '../Typography'
import { customMedia } from '../../common/MediaQueries'
import { matchMedia } from '../../common/utils'

const Statistics = ({ value, color, afterfix, intervalDelay }) => {
  const [isVisible, setIsVisible] = useState(false)
  const hasComma = value?.toString().includes(',')
  const fixedValue = value?.toString().replace(',', '.')
  const hasDecimals = fixedValue.includes('.')
  const aboveDesktop = matchMedia(`${customMedia.fullWidth}`).matches

  const countDecimals = (num) => {
    const textNumber = num.toString()
    const index = textNumber.indexOf('.')
    return index === -1 ? 0 : textNumber.length - index - 1
  }

  const onVisibilityChange = (visibility) => {
    if (visibility) {
      setIsVisible(true)
    }
  }

  return (
    <StatisticsStyled>
      <Heading9 fontWeight="medium" {...{ color }}>
        <VisibilitySensor
          onChange={onVisibilityChange}
          offset={{
            top: aboveDesktop ? 300 : 100,
          }}
          scrollDelay={250}
          intervalDelay={intervalDelay && aboveDesktop ? intervalDelay : 100}
          delayedCall
          resizeCheck
          scrollCheck
        >
          <CountUp
            start={0}
            end={isVisible ? fixedValue : 0}
            decimal={hasComma ? ',' : '.'}
            decimals={hasDecimals ? countDecimals(fixedValue) : 0}
          />
        </VisibilitySensor>
        {!!afterfix && afterfix}
      </Heading9>
    </StatisticsStyled>
  )
}

export default Statistics

Statistics.propTypes = {
  value: PropTypes.number || PropTypes.string,
  color: PropTypes.string,
  afterfix: PropTypes.string,
  intervalDelay: PropTypes.number || PropTypes.string,
}
