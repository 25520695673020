import React from 'react'

import { IconWrapper } from '../../common/mixins'

const DropIconSimple = () => (
  <IconWrapper>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="277px"
      height="416px"
      viewBox="0 0 277 416"
      version="1.1"
    >
      <defs>
        <polygon id="path-1" points="0 0 277 0 277 416 0 416" />
      </defs>
      <g
        id="Desktop"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="SES-Water-AR-Desktop-–-videos/-rollovers"
          transform="translate(-580.000000, -6914.000000)"
        >
          <g id="Group-4" transform="translate(580.000000, 6914.000000)">
            <g xmlns="http://www.w3.org/2000/svg" id="Group-3-Copy">
              <g id="Clip-2" />
              <path
                d="M208.707718,130.036749 C185.460872,100.077805 161.447851,69.1015637 150.413074,35.8760685 L138.509344,0 L126.577582,35.8760685 C115.552149,69.1015637 91.5204412,100.077805 68.2922823,130.036749 C34.7114282,173.31389 0,218.065645 0,273.401028 C0,352.031589 62.134858,416 138.509344,416 C214.865142,416 277,352.031589 277,273.401028 C277,218.065645 242.288572,173.31389 208.707718,130.036749"
                id="drop-fill"
                fill="#648C2E"
              />
            </g>
            <path
              d="M145.251302,20.1664042 L138.509344,2.4158453e-13 L131.28028,20.5328317 C127.551422,31.7563573 123.629549,45.2354673 118.268083,56.0802326 C116.090351,60.4851835 114.459132,63.671838 112.334349,67.3650996 C110.579016,70.4161886 108.864647,74.0715985 106.397206,77.2502003 C87.154859,102.018863 190.391193,102.778599 171.148846,78.0099357 C159.328393,60.3538675 151.574399,39.1822421 145.251302,20.1664042 Z"
              id="Fill-1-Copy-2"
              fill="#648C2E"
            />
          </g>
        </g>
      </g>
    </svg>
  </IconWrapper>
)

export default DropIconSimple
