import styled from 'styled-components'

import media from '../../../../common/MediaQueries'
import { StyledAboutTile } from '../../styles'

export const StyledProgressSectionColBox = styled.div`
  display: flex;
  flex-direction: column-reverse;
  @media ${media.phone} {
    flex-direction: row;
  }
`

export const StyledProgressSectionCol = styled.div`
  @media ${media.phone} {
    width: 50%;

    &:first-child {
      padding-right: 2.625rem;
    }

    &:not(:first-child) {
      padding-left: 2.625rem;
    }

    ${StyledAboutTile} {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;

      > div {
        margin-left: 0;
      }
    }
  }
`

export const StyledProgressSectionGraph = styled.div`
  width: 13.75rem;
  margin: 1rem auto 0;

  // ie fix
  > div > div {
    height: 13.75rem !important;
  }

  @media ${media.phone} {
    margin-right: 0;
    margin-top: 0;
    width: 23.375rem;
    > div > div {
      height: 23.375rem !important;
    }
  }
`

export const StyledProgressSectionIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.purple};
  border-radius: 50%;
  height: 8.25rem;
  width: 8.25rem;

  svg {
    font-size: 4.5rem;
  }

  @media ${media.phone} {
    height: 14.25rem;
    width: 14.25rem;
    svg {
      font-size: 8rem;
    }
  }
`
