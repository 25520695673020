import React from 'react'

import { IconWrapper } from '../../common/mixins'

const ProgressIcon = () => (
  <IconWrapper>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="128"
      viewBox="0 0 128 128"
    >
      <defs>
        <path id="a" d="M0 0h128v128H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <path
            d="M106.302 106.099c-11.337 11.315-26.453 17.4-42.302 17.4-32.913 0-59.734-26.77-59.734-59.621 0-15.817 6.096-30.904 17.434-42.22 11.337-11.315 26.453-17.4 42.3-17.4 15.849 0 30.965 6.085 42.302 17.4 11.337 11.316 17.433 26.403 17.433 42.22 0 15.818-6.096 30.906-17.433 42.221m19.626-57.795l-1.097-4.502a63.154 63.154 0 00-15.481-25.186C97.28 6.571 81.19 0 64.122 0 47.056 0 30.964 6.571 18.895 18.616A63.154 63.154 0 003.413 43.802l-1.341 4.26c-1.22 5.11-2.073 10.34-2.073 15.695 0 5.354.733 10.707 2.073 15.695l1.219 4.624c3.17 9.491 8.533 18.251 15.483 25.186C30.354 120.822 46.324 128 63.879 128c17.066 0 33.158-6.57 45.226-18.616 7.192-7.179 12.435-15.817 15.483-25.186l1.34-4.502C127.148 74.586 128 69.353 128 64s-.731-10.706-2.072-15.696"
            fill="#FFF"
          />
        </g>
        <path
          d="M95.6 55.097c0 2.191-.855 4.26-2.444 5.843-1.467 1.947-3.544 2.8-5.865 2.679H41.588c-4.4 0-8.432-4.019-8.432-8.4V52.54c0-4.626 3.789-8.4 8.432-8.4h45.703c2.198 0 4.277.853 5.865 2.435 1.589 1.582 2.444 3.653 2.444 5.843v2.679zm-8.309-15.095H41.588c-7.087 0-12.709 5.6-12.586 12.538v2.679c0 3.409 1.222 6.573 3.665 9.007 2.444 2.436 5.622 3.653 8.921 3.774h45.703c3.422 0 6.476-1.338 8.92-3.774 2.444-2.434 3.666-5.598 3.789-8.887l-.123-2.799c0-6.817-5.865-12.66-12.586-12.538z"
          fill="#FFF"
        />
        <path
          d="M96.23 96.229c-8.455 8.7-20.098 13.48-32.229 13.357-12.254 0-23.651-4.779-32.23-13.357-8.578-8.579-13.48-20.099-13.357-32.23 0-12.255 4.779-23.651 13.357-32.23 8.579-8.579 20.099-13.48 32.23-13.357 12.5 0 24.019 5.148 32.23 13.357C104.442 39.98 109.588 51.5 109.588 64c0 12.254-4.778 23.651-13.357 32.23M64 14c-13.235 0-25.857 5.27-35.294 14.705C19.272 38.142 14.125 50.642 14.002 64c-.123 13.358 5.27 25.857 14.705 35.294 9.437 9.435 21.937 14.582 35.294 14.705 13.359.123 25.857-5.27 35.294-14.705 9.435-9.437 14.582-21.936 14.705-35.294C113.877 36.426 91.574 14.123 64.001 14"
          fill="#FFF"
        />
        <path
          d="M83 82a5 5 0 000 10 5 5 0 000-10M59 82a5 5 0 000 10 5 5 0 000-10M45 82a5 5 0 000 10 5 5 0 000-10"
          fill="#FFF"
        />
      </g>
    </svg>
  </IconWrapper>
)

export default ProgressIcon
