import React from 'react'

import { IconWrapper } from '../../common/mixins'

const CloseIconBold = () => (
  <IconWrapper>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
    >
      <g fill="none" fillRule="evenodd">
        <g transform="rotate(45 9.415 14.485)" stroke="currentColor" strokeWidth="2.2">
          <circle cx="10.9" cy="10.9" r="10.9" />
          <path
            d="M10.9 5.663v10.473m4.727-5.236H5.445"
            strokeLinecap="round"
          />
        </g>
      </g>
    </svg>
  </IconWrapper>
)

export default CloseIconBold
