import PledgeIcon1 from '../../assets/icon-pledge-1.svg'
import PledgeIcon2 from '../../assets/icon-pledge-2.svg'
import PledgeIcon3 from '../../assets/icon-pledge-3.svg'
import PledgeIcon4 from '../../assets/icon-pledge-4.svg'
import PledgeIcon5 from '../../assets/icon-pledge-5.svg'

export const aims = [
  {
    id: 0,
    bg: 'valhalla',
    bgDetails: 'prupleLight',
    teaserTitle: 'Pledge 1',
    icon: PledgeIcon1,
    title: 'We will provide you with high quality water all day, every day',
    desc:
      'Nothing is more important to us than striving to keep our customers supplied with safe, clean water, especially during a global pandemic.',
    measures: [
      {
        head: 'Water quality – DWI <br /> Compliance Risk Index (CRI) <br/> <span>score</span>',
        isAlt: true,
        items: [
          {
            title: '2020 performance',
            subtitle: '>2',
          },
          {
            title: 'target/target limit',
            subtitle: '0.00',
          },
        ],
        additionalInfo: "The Compliance Risk Index for SES Water will be published in the Drinking Water Inspectorate’s Chief Inspector’s Report – Drinking Water 2020 - which is due to be published in July 2021."
      },
      {
        head: 'Supply interruptions <br /><span class="alt-spacing">hours&nbsp;/ minutes&nbsp;/ seconds&nbsp;/ property&nbsp;/ year</span>',
        isAlt: true,
        items: [
          {
            title: '2020/21 performance',
            subtitle: '00:06:56',
          },
          {
            title: 'target/target limit',
            subtitle: '00:06:30',
          },
        ],
      },
      {
        head: 'Mains repairs <br /> <span>number/1,000 kilometres</span>',
        isAlt: false,
        items: [
          {
            title: '2020/21 performance',
            subtitle: '64.7',
          },
          {
            title: 'target/target limit',
            subtitle: '66.5',
          },
        ],
      },
      {
        head: 'Taste, odour and <br /> discolouration contacts <br /> <span>number per 1000 customers</span>',
        isAlt: true,
        items: [
          {
            title: '2020 performance',
            subtitle: '0.56',
          },
          {
            title: 'target/target limit',
            subtitle: '0.51',
          },
        ],
      },
      {
        head: 'Water softening <br /> <span>number of periods exceeding calcium target</span>',
        isAlt: true,
        items: [
          {
            title: '2020 performance',
            subtitle: '6.3',
          },
          {
            title: 'target/target limit',
            subtitle: '0.0',
          },
        ],
      },
    ],
  },
  {
    id: 1,
    bg: 'orange',
    bgDetails: 'orangeLight',
    teaserTitle: 'Pledge 2',
    icon: PledgeIcon2,
    title:
      'We will provide your service at a fair price <br />and offer help when <br />you need it',
    desc:
      'This year we have been able to support our customers to pay their bill and extended our financial support options when they have needed it the most.',
    measures: [
      {
        head: 'Supporting customers <br /> in financial hardship <br /> <span>number</span>',
        isAlt: false,
        items: [
          {
            title: '2020/21 performance',
            subtitle: '20,274',
          },
          {
            title: 'target/target limit',
            subtitle: '12,960',
          },
        ],
      },
      {
        head: 'Void properties <br /> <span>%</span>',
        isAlt: true,
        items: [
          {
            title: '2020/21 performance',
            subtitle: '4.42',
          },
          {
            title: 'target/target limit',
            subtitle: '2.80',
          },
        ],
      },
      {
        head: 'Customers on our Priority <br /> Services Scheme <br /> <span>%</span>',
        isAlt: false,
        items: [
          {
            title: '2020/21 performance',
            subtitle: '4.5',
          },
          {
            title: 'target/target limit',
            subtitle: '3.5',
          },
        ],
      },
      {
        head: 'Vulnerable support <br /> scheme awareness <br /> <span>%</span>',
        isAlt: true,
        items: [
          {
            title: '2020/21 performance',
            subtitle: '36.5',
          },
          {
            title: 'target/target limit',
            subtitle: '56.7',
          },
        ],
      },
      {
        head: 'Vulnerable support <br /> scheme helpfulness <br /> <span>%</span>',
        isAlt: false,
        items: [
          {
            title: '2020/21 performance',
            subtitle: '95.0',
          },
          {
            title: 'target/target limit',
            subtitle: '80.0',
          },
        ],
      },
      {
        head: 'Proportion of customers who <br />believe their bill is not good value <br /> <span>% dissatisfied</span>',
        isAlt: false,
        items: [
          {
            title: '2020/21 performance',
            subtitle: '7',
          },
          {
            title: 'target/target limit',
            subtitle: '9',
          },
        ],
      },
    ],
  },
  {
    id: 2,
    bg: 'tealDark',
    bgDetails: 'teal',
    teaserTitle: 'Pledge 3',
    icon: PledgeIcon3,
    title:
      'We will provide you with a service that is fit now and for the future',
    desc:
      'We target our investment in our infrastructure every year where it is needed most and are using innovative technology to provide a better service to our customers.',
    measures: [
      {
        head: 'Leakage reduction <br /> <span>%</span>',
        isAlt: false,
        items: [
          {
            title: '2020/21 performance',
            subtitle: '1.2',
          },
          {
            title: 'target/target limit',
            subtitle: '1.2',
          },
        ],
      },
      {
        head: 'Unplanned outages <br /> at treatment works <br /> <span>%</span>',
        isAlt: false,
        items: [
          {
            title: '2020/21 performance',
            subtitle: '0.95',
          },
          {
            title: 'target/target limit',
            subtitle: '2.34',
          },
        ],
      },
      {
        head: 'Risk of supply failures <br /> <span>% of properties connected to more than one treatment works </span>',
        isAlt: true,
        items: [
          {
            title: '2020/21 performance',
            subtitle: '56',
          },
          {
            title: 'target/target limit',
            subtitle: '65',
          },
        ],
      },
      {
        head: 'Risk of severe restrictions <br /> in a drought <br /> <span>% customers</span>',
        isAlt: false,
        items: [
          {
            title: '2020/21 performance',
            subtitle: '0.0',
          },
          {
            title: 'target/target limit',
            subtitle: '0.0',
          },
        ],
      },
    ],
  },
  {
    id: 3,
    bg: 'plum',
    bgDetails: 'plumLight',
    teaserTitle: 'Pleadge 4',
    icon: PledgeIcon4,
    title:
      'We will provide excellent <br /> service, whenever and however you need it',
    desc:
      'We want the most satisfied customers in the country and to get there we are fundamentally changing a lot of what we do and significantly investing in our people and the systems they use.',
    measures: [
      {
        head: 'C-MeX (industry measure of customer satisfaction) <br /> <span>score</span>',
        isAlt: true,
        items: [
          {
            title: '2020/21 performance',
            subtitle: '78.97',
          },
          {
            title: 'target/target limit',
            subtitle: '<span>Upper quartile</span>',
          },
        ],
      },
      {
        head: 'D-MeX (industry measure of developer satisfaction) <br /> <span>score</span>',
        isAlt: true,
        items: [
          {
            title: '2020/21 performance',
            subtitle: '60.20',
          },
          {
            title: 'target/target limit',
            subtitle: '<span>Upper quartile</span>',
          },
        ],
      },
      {
        head: 'First contact resolution <br /> <span>%</span>',
        isAlt: false,
        items: [
          {
            title: '2020/21 performance',
            subtitle: '83.9',
          },
          {
            title: 'target/target limit',
            subtitle: '80.0',
          },
        ],
      },
    ],
  },
  {
    id: 4,
    bg: 'greenLight',
    bgDetails: 'springGreen',
    teaserTitle: 'Pledge 5',
    title: 'We will support a thriving environment we can all rely upon',
    icon: PledgeIcon5,
    desc:
      'We are committed to reducing the impact of our operations by achieving net zero carbon emissions by 2030 and continuing to implement more sustainable ways of pumping, treating and distributing millions of litres of water every single day.',
    measures: [
      {
        head: 'Consumption </br> reduction <br /> <span>%</span>',
        isAlt: true,
        items: [
          {
            title: '2020/21 performance',
            subtitle: '(3.7)',
          },
          {
            title: 'target/target limit',
            subtitle: '1.0',
          },
        ],
      },
      {
        head: 'Greenhouse gas emissions <br /> <span>kgCO<sub>2</sub>e/megalitre</span>',
        isAlt: false,
        items: [
          {
            title: '2020/21 performance',
            subtitle: '40',
          },
          {
            title: 'target/target limit',
            subtitle: '55',
          },
        ],
      },
      {
        head: 'River based improvement <br /> – delivery of Water Industry <br /> National Environment Programme <br /> (WINEP) <span>number of schemes</span>',
        isAlt: false,
        items: [
          {
            title: '2020/21 performance',
            subtitle: '0',
          },
          {
            title: 'target/target limit',
            subtitle: '0',
          },
        ],
      },
      {
        head: 'Pollution incidents <br /> <span>number of category one and two incidents</span>',
        isAlt: false,
        items: [
          {
            title: '2020 performance',
            subtitle: '0',
          },
          {
            title: 'target/target limit',
            subtitle: '0',
          },
        ],
      },
      {
        head: 'Abstraction incentive <br /> mechanism <br /> <span>average megalitres reduction</span>',
        isAlt: false,
        items: [
          {
            title: '2020/21 performance',
            subtitle: '<span>Not triggered</span>',
          },
          {
            title: 'target/target limit',
            subtitle: '-7',
          },
        ],
      },
      {
        head: 'Land-based improvement <br/> – biodiversity <br /><span>number of sites awarded benchmark</span>',
        isAlt: false,
        items: [
          {
            title: '2020/21 performance',
            subtitle: '1',
          },
          {
            title: 'target/target limit',
            subtitle: '1',
          },
        ],
      },
      {
        head: 'Deliver WINEP requirements <br /><span>delivered</span>',
        isAlt: false,
        items: [
          {
            title: '2020/21 performance',
            subtitle: '<span>Met</span>',
          },
          {
            title: 'target/target limit',
            subtitle: '<span>Met</span>',
          },
        ],
      },
    ],
  },
]
