import React, { useState } from 'react'
import PropTypes from 'prop-types'
import VisibilitySensor from 'react-visibility-sensor'

import { StyledAboutTile } from '../../styles'
import {
  StyledProgressSectionColBox,
  StyledProgressSectionCol,
  StyledProgressSectionGraph,
  StyledProgressSectionIcon,
} from './styles'
import SimpleSection from '../SimpleSection'
import theme from '../../../../themes/theme'
import ProgressIcon from '../../../Icons/ProgressIcon'
import ProgressBar from '../../../ProgressBar'

const circularBarConfig = {
  pathColor: theme.colors.eminence,
  trailColor: theme.colors.valhalla,
}

const ProgressSection = ({ title, titleColor, count }) => {
  const [isVisible, setIsVisible] = useState(false)

  const onVisibilityChange = (visibility) => {
    if (visibility) {
      setIsVisible(true)
    }
  }

  return (
    <StyledAboutTile>
      <StyledProgressSectionColBox>
        <StyledProgressSectionCol>
          <StyledProgressSectionGraph>
            <VisibilitySensor
              onChange={onVisibilityChange}
              delayedCall
              resizeCheck
              intervalDelay={400}
              scrollDelay={1000}
            >
              <ProgressBar
                state={isVisible}
                valueStart={0}
                value={62}
                pathWidth={7}
                config={circularBarConfig}
              >
                <StyledProgressSectionIcon>
                  <ProgressIcon />
                </StyledProgressSectionIcon>
              </ProgressBar>
            </VisibilitySensor>
          </StyledProgressSectionGraph>
        </StyledProgressSectionCol>
        <StyledProgressSectionCol>
          <SimpleSection {...{ title, titleColor, count }} afterfix="%" />
        </StyledProgressSectionCol>
      </StyledProgressSectionColBox>
    </StyledAboutTile>
  )
}

export default ProgressSection

ProgressSection.propTypes = {
  title: PropTypes.string,
  titleColor: PropTypes.string,
  count: PropTypes.string,
}
