import React, { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'

import {
  StyledReport,
  StyledReportInner,
  StyledReportHeader,
  StyledReportNav,
  StyledReportList,
  StyledReportItem,
  StyledReportLink,
  StyledReportContent,
  StyledReportBgOverlay,
} from './styles'
import { Heading7 } from '../Typography'
import NavigationLink from '../Links/NavigationLink'
import NavigationContext from '../../contexts/NavigationContext'
import { matchMedia } from '../../common/utils'
import media from '../../common/MediaQueries'
import NAVIGATION from '../../constants/navigation'
import Heading from '../Heading'
import theme from '../../themes/theme'

const Report = ({ staticNav }) => {
  const {
    images: { edges: reports },
  } = useStaticQuery(
    graphql`
      query {
        images: allImageSharp(
          filter: { original: { src: { regex: "/report/" } } }
          sort: { fields: original___src }
        ) {
          edges {
            node {
              id
              fluid(maxWidth: 446, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    `,
  )

  const reportImages = reports.map(({ node: { id, fluid } }) => {
    return { id, fluid }
  })

  const reportTiles = new Array(reportImages.length).fill('').map((_, i) => {
    return { ...reportImages[i], ...NAVIGATION[i] }
  })

  const { handleClick } = useContext(NavigationContext)
  const abovePhone = matchMedia(`${media.phone}`).matches

  return (
    <StyledReport>
      <StyledReportInner>
        <StyledReportHeader>
          <Heading
            title="What's in this report"
            color={theme.colors.valhalla}
          />
        </StyledReportHeader>
        <StyledReportNav>
          <StyledReportList>
            {reportTiles.map(({ id, fluid, label, to, action }) => (
              <StyledReportItem key={id}>
                <StyledReportLink
                  to={to}
                  href={`#${to}`}
                  spy
                  smooth
                  delay={staticNav ? 70 : 200}
                  offset={abovePhone ? -80 : -72}
                  onClick={!staticNav ? handleClick : undefined}
                >
                  <Img fluid={fluid} />
                  <StyledReportBgOverlay />
                  <StyledReportContent>
                    <Heading7 color="white">{ReactHtmlParser(label)}</Heading7>
                    <NavigationLink color="white" label={action} />
                  </StyledReportContent>
                </StyledReportLink>
              </StyledReportItem>
            ))}
          </StyledReportList>
        </StyledReportNav>
      </StyledReportInner>
    </StyledReport>
  )
}

export default Report

Report.propTypes = {
  staticNav: PropTypes.bool,
}
