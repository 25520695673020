import React from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'

import {
  StyledMeasures,
  StyledMeasuresHead,
  StyledMeasureHeadTypo,
  StyledMeasuresRow,
  StyledMeasuresList,
  StyledMeasuresListItem,
  StyledMeasuresDecor,
  StyledMeasuresListSubtitle,
  StyledMeasuresScript,
} from './styles'
import { Typo5, Typo7 } from '../Typography'
import Container from '../Container'

const Measures = ({ measures }) => (
  <StyledMeasures>
    {measures.map(({ head, items, isAlt, additionalInfo }, index) => (
      <StyledMeasuresRow key={`${index}-${head}`}>
        <StyledMeasuresHead>
          <StyledMeasuresDecor {...{ isAlt }} />
          <StyledMeasureHeadTypo mt="3px">
            <Typo7 fontWeight="bold">{ReactHtmlParser(head)}</Typo7>
          </StyledMeasureHeadTypo>
        </StyledMeasuresHead>
        <StyledMeasuresList>
          {items.map(({ title, subtitle }) => (
            <StyledMeasuresListItem key={`${index}-${title}`}>
              <Container mb={['0.75rem']}>
                <Typo5 fontWeight="medium">{ReactHtmlParser(title)}</Typo5>
              </Container>
              <StyledMeasuresListSubtitle fontWeight="medium">
                {ReactHtmlParser(subtitle)}
              </StyledMeasuresListSubtitle>
            </StyledMeasuresListItem>
          ))}
        </StyledMeasuresList>
        {additionalInfo && (
          <StyledMeasuresScript lineHeight="1.2">
            {ReactHtmlParser(additionalInfo)}
          </StyledMeasuresScript>
        )}
      </StyledMeasuresRow>
    ))}
  </StyledMeasures>
)

export default Measures

Measures.propTypes = {
  measures: PropTypes.arrayOf(
    PropTypes.shape({
      head: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          subtitle: PropTypes.string,
        }),
      ),
      isAlt: PropTypes.bool,
    }),
  ),
}
