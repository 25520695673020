import React, { useState } from 'react'
import PropTypes from 'prop-types'
import VisibilitySensor from 'react-visibility-sensor'

import {
  StyledProgressValueSection,
  StyledProgressValueSectionInfo,
  StyledProgressValueSectionBar,
  StyledProgressValueSectionGraph,
  StyledProgressValueSectionIcon,
} from './styles'
import SimpleSection from '../SimpleSection'
import ProgressBar from '../../../ProgressBar'
import DropIconSimple from '../../../Icons/DropIconSimple'
import { circularBarConfig, circularBarConfigSecond } from './consts'

const ProgressValueSection = ({ topData, bottomData }) => {
  const [isVisible, setIsVisible] = useState(false)

  const onVisibilityChange = (visibility) => {
    if (visibility) {
      setIsVisible(true)
    }
  }

  return (
    <StyledProgressValueSection>
      <StyledProgressValueSectionInfo {...{ isVisible }}>
        <SimpleSection count={topData.count} afterfix="%" intervalDelay={580} />
      </StyledProgressValueSectionInfo>
      <VisibilitySensor
        onChange={onVisibilityChange}
        offset={{
          top: 100,
        }}
        delayedCall
        resizeCheck
        scrollCheck
      >
        <StyledProgressValueSectionBar>
          <StyledProgressValueSectionGraph>
            <ProgressBar
              state={isVisible}
              value="15"
              config={circularBarConfig}
              directionLeft
            >
              <StyledProgressValueSectionIcon>
                <DropIconSimple />
              </StyledProgressValueSectionIcon>
            </ProgressBar>
          </StyledProgressValueSectionGraph>

          <StyledProgressValueSectionGraph>
            <ProgressBar
              state={isVisible}
              value="82"
              config={circularBarConfigSecond}
            />
          </StyledProgressValueSectionGraph>
        </StyledProgressValueSectionBar>
      </VisibilitySensor>
      <StyledProgressValueSectionInfo {...{ isVisible }}>
        <SimpleSection count={bottomData.count} afterfix="%" />
      </StyledProgressValueSectionInfo>
    </StyledProgressValueSection>
  )
}

export default ProgressValueSection

ProgressValueSection.propTypes = {
  topData: PropTypes.object,
  bottomData: PropTypes.object,
}
