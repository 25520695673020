import styled from 'styled-components'
import { Link } from 'react-scroll'
import { rgba } from 'polished'

import Inner from '../Inner'
import media from '../../common/MediaQueries'
import { transition } from '../../common/mixins'
import { StyledNavigationLinkLabel } from '../Links/NavigationLink/styles'

const StyledReport = styled.div`
  position: relative;
  padding-top: 5rem;
  padding-bottom: 5rem;
  text-align: center;
  width: 100%;
  z-index: 1;

  @media ${media.tablet} {
    padding-top: 7.5rem;
  }
`

const StyledReportInner = styled(Inner)``

const StyledReportHeader = styled.div`
  margin-bottom: 1.75rem;

  @media ${media.phone} {
    margin-bottom: 5.125rem;
  }
`

const StyledReportNav = styled.nav`
  max-width: 57rem;
  margin: 0 auto;
`

const StyledReportList = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: -1.125rem;

  @media ${media.phone} {
    flex-direction: row;
    flex-wrap: wrap;
    width: calc(100% + 1.25rem);
    margin-left: -0.625rem;
    margin-top: -1.25rem;
  }
`

const StyledReportItem = styled.li`
  margin-top: 1.125rem;

  @media ${media.phone} {
    width: calc(50% - 1.25rem);
    margin: 1.25rem 0.625rem 0 0.625rem;
  }
`

const StyledReportBgOverlay = styled.div`
  ${transition}
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => rgba(theme.colors.valhalla, 0.6)};
  z-index: 0;
`

const StyledReportLink = styled(Link)`
  position: relative;
  display: block;
  cursor: pointer;
  height: 13.25rem;
  ${transition}

  .gatsby-image-wrapper {
    position: absolute !important;
    height: 100%;
    width: 100%;
  }

  &:hover {
    ${StyledReportBgOverlay} {
      background: ${({ theme }) => rgba(theme.colors.valhalla, 0.75)};
    }
    ${StyledNavigationLinkLabel} {
      text-decoration: underline;
    }
  }

  @media ${media.tablet} {
    height: 20rem;
  }
`

const StyledReportContent = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  max-width: 20.75rem;
  left: 50%;
  top: 4.875rem;
  padding: 0 1rem;
  transform: translateX(-50%);

  > * + * {
    margin-top: 1rem;
  }

  > p {
    min-height: 3.75rem;
  }

  @media ${media.phone} {
    top: 50%;
    transform: translate(-50%, -50%);

    > p {
      min-height: auto;
    }
  }
`

export {
  StyledReport,
  StyledReportInner,
  StyledReportHeader,
  StyledReportNav,
  StyledReportList,
  StyledReportItem,
  StyledReportLink,
  StyledReportBgOverlay,
  StyledReportContent,
}
