import styled, { css } from 'styled-components'
import { Link } from 'react-scroll'
import { rgba } from 'polished'

import media, { customMedia } from '../../common/MediaQueries'
import Inner from '../../components/Inner'
import { Heading10, Typo15 } from '../Typography'
import { transition } from '../../common/mixins'

export const StyledHero = styled.div`
  display: flex;
  align-items: flex-end;
  position: relative;
  overflow: hidden;
  height: 100%;
  min-height: 20.625rem;
  width: 100%;
  max-width: 90rem;
  margin: 0 auto;

  @media ${media.phone} {
    padding-top: 56.25%;
    height: 100%;
    min-height: 35.5rem;
  }

  @media ${media.tablet} {
    max-height: calc(100vh - 5rem);
    height: 55.875rem;
    padding-top: 0%;
  }

  @media ${customMedia.maxPhone} {
    height: calc(100vh - 17.1875rem - 3.5rem);
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        height: calc(
          100vh - 17.1875rem - 6.25rem
        ); // last value - value of the nav bottom bar
      }
    }
  }
`

export const StyledHeroOverlay = styled.div`
  height: 100%;
  width: 100%;
  background: ${({ theme }) => rgba(theme.colors.valhalla, 0.6)};
  position: absolute;
  z-index: 1;
`

export const StyledHeroMediaBox = styled.div``

export const StyledHeroMedia = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  overflow: hidden;
  ${transition}

  ${({ isAlt }) =>
    isAlt &&
    css`
      opacity: 0;
      background-color: ${({ theme }) => theme.colors.black};
    `}

  ${({ isVideoPlaying }) =>
    isVideoPlaying &&
    css`
      opacity: 1;
      z-index: 3;
    `}
`

export const StyledHeroLoader = styled.div`
  position: relative;
  display: inline-block;
  top: calc(50% - 2.5rem);
  left: calc(50% - 2.5rem);
  z-index: 2;
  text-align: center;

  svg {
    font-size: 5rem;
  }
`

export const StyledHeroClose = styled.a`
  position: absolute;
  right: 1.75rem;
  top: 1.25rem;
  z-index: 4;

  svg {
    font-size: 2.5rem;
    color: ${({ theme }) => theme.colors.white};
  }

  @media ${media.phone} {
    ${transition}
    &:hover {
      opacity: 0.8;
    }
  }
`

export const StyledHeroImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 0;
  transform: translateX(-50%) translateY(-50%);
`

export const StyledHeroVideo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  overflow: hidden;
`

export const StyledHeroContent = styled(Inner)`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 2.375rem;
  ${transition}

  ${({ isHidden }) =>
    isHidden &&
    css`
      opacity: 0;
    `}

  @media ${customMedia.maxPhone} {
    padding-left: 1.375rem;
    padding-right: 1.375rem;
  }

  @media ${media.phone} {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 3.625rem;
    max-width: calc(75rem + (2 * 1.75rem));

    &::after {
      position: absolute;
      display: block;
      content: '';
      width: 8.375rem;
      height: 1.5rem;
      bottom: 0;
      left: -5.75rem;
      background-color: ${({ theme }) => theme.colors.teal};
    }
  }
`

export const StyledHeroInfo = styled.div`
  @media ${media.phone} {
    width: calc(100% - 15.125rem);
  }
`

export const StyledHeroPlayBox = styled.div`
  margin-top: 2rem;
  @media ${media.phone} {
    margin-top: 0;
    margin-bottom: 1.5rem;
    width: 15.125rem;
  }
`

export const StyledHeroPlay = styled.a`
  display: block;
`

export const StyledHeroHead = styled(Heading10)``

export const StyledHeroSubhead = styled(Typo15)`
  display: none;
  @media ${media.phone} {
    display: block;
    margin-top: 1.75rem;
  }
`

export const StyledHeroScroll = styled(Link)`
  display: none;
  margin-top: 1rem;
  width: 100%;
  svg {
    font-size: 2.6875rem;
    width: 2.72em;
  }
  @media ${media.phone} {
    display: block;
    ${transition}
    &:hover {
      opacity: 0.8;
    }
  }
`
