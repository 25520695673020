import styled, { css } from 'styled-components'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'

import media from '../../common/MediaQueries'
import { transition } from '../../common/mixins'
import {
  StyledSliderNavigationPrev,
  StyledSliderNavigationNext,
} from '../SliderNavigation/styles'
import { StyledAimTeaser, StyledAimTeaserContent } from './AimTeaser/styles'

const StyledAim = styled.div`
  max-width: 71.875rem;
  margin: 0 auto;
`

const StyledAimDetailsWrapper = styled.div`
  ${transition}
  position: relative;
  display: block;
  opacity: 0;
  visibility: hidden;
  height: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: ${({ theme }) => theme.colors.white};

  .slick-track {
    display: flex;
    height: 100%;
  }

  .slick-slider,
  .slick-list {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .slick-slide {
    display: flex;
    width: 100%;
    height: auto;

    div {
      &:focus {
        outline: none;
      }
    }

    div:not([class]) {
      display: flex;
      width: 100%;
      height: 100%;
    }
  }

  ${({ isDetailsActive }) =>
    isDetailsActive &&
    css`
      visibility: visible;
      opacity: 1;
      height: auto;
    `};
`

const StyledAimDetails = styled.div`
  overflow-y: auto;
  height: 100%;
  width: 100%;
  margin: 0 auto;
`

const StyledAimDetailsContentBox = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const StyledAimDetailsContent = styled.div`
  position: relative;
  margin-top: auto;
  margin-bottom: auto;
  height: 100%;
`

const StyledAimDetailsSlider = styled(Slider)`
  display: flex;

  @media ${media.phone} {
    ${StyledSliderNavigationPrev},
    ${StyledSliderNavigationNext} {
      top: auto;
      bottom: 2.5rem;
      transform: none;
      color: ${({ theme }) => theme.colors.white};
    }

    ${StyledSliderNavigationPrev} {
      left: 1.5rem;
    }

    ${StyledSliderNavigationNext} {
      right: 1.5rem;
    }
  }

  @media ${media.tablet} {
    ${StyledSliderNavigationPrev},
    ${StyledSliderNavigationNext} {
      &:hover {
        opacity: 0.8;
      }
    }
  }
`

const StyledAimDetailsItem = styled.div`
  width: 100%;
  height: 100%;
`

const StyledAimTeaserList = styled.ul`
  display: flex;
  flex-direction: column;

  @media ${media.phone} {
    ${({ isDetailsActive }) =>
      isDetailsActive &&
      css`
        opacity: 0.4;
      `}
  }
`

const StyledAimTeaserItem = styled.li`
  position: relative;
  width: 100%;

  &:not(:first-child) {
    margin-top: 1.5rem;
  }

  @media ${media.phone} {
    &::before {
      position: absolute;
      display: block;
      content: '';
      background-color: ${({ theme }) => theme.colors.white};
      height: 100%;
      width: 100vw;
      top: 0;
    }

    &:not(:first-child) {
      margin-top: 2.5rem;
    }

    &:nth-child(odd) {
      &::before {
        right: -100vw;
      }
    }

    &:nth-child(even) {
      ${StyledAimTeaserContent} {
        padding-left: 0;
      }
      ${StyledAimTeaser} {
        flex-direction: row-reverse;
      }
      &::before {
        left: -100vw;
      }
    }
  }
`

const StyledAimDetailsClose = styled.button`
  display: block;
  position: absolute;
  top: 1.75rem;
  right: 1.5rem;
  z-index: 1;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;

  svg {
    font-size: 1.875rem;
  }

  @media ${media.phone} {
    ${transition};
    &:hover {
      opacity: 0.8;
    }
    svg {
      font-size: 2rem;
    }
  }
`

const StyledAimBanner = styled.div`
  position: relative;
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.valhalla};
  padding: 3.75rem 1.5rem 3.25rem;

  br {
    display: none;
  }

  @media ${media.phone} {
    margin-bottom: 4.625rem;
    padding: 10rem 0 8.875rem;
    width: 80%;

    br {
      display: block;
    }

    &::before {
      position: absolute;
      display: block;
      content: '';
      width: 100vw;
      top: 0;
      height: 100%;
      left: -100vw;
      background-color: inherit;
    }
  }
`

export {
  StyledAim,
  StyledAimTeaserList,
  StyledAimTeaserItem,
  StyledAimDetails,
  StyledAimDetailsContentBox,
  StyledAimDetailsContent,
  StyledAimDetailsWrapper,
  StyledAimDetailsSlider,
  StyledAimDetailsItem,
  StyledAimDetailsClose,
  StyledAimBanner,
}
