export const aboutDetails = [
  { // 0
    title: 'Who we are',
    titleColor: 'valhalla',
    description:
      'We are a local water supplier providing an essential public service while playing an active role in the communities we are privileged to serve',
    count: {
      countValue: '',
      countValueColor: '',
      countSubtitle: '',
      countSubtitleSmall: false,
    },
  },
  { // 1
    title: 'What we do',
    titleColor: 'teal',
    description:
      'We supply around 160 million litres of clean water every day <br />to more than ',
    count: {
      countValue: '735,000',
      countValueColor: 'valhalla',
      countSubtitle: 'people',
      countSubtitleSmall: false,
    },
  },
  { // 2
    description: 'in parts of Surrey, Kent, West Sussex, and south London',
  },
  { // 3
    title: 'Why we do it',
    titleColor: 'crete',
    description:
      'To be an outstanding water company that delivers service excellence',
    count: {
      countValue: '',
      countValueColor: '',
      countSubtitle: '',
      countSubtitleSmall: false,
    },
  },
  { // 4
    title: 'Our business <br /> in numbers',
    titleColor: 'lightBlue',
    description: '',
    count: {
      countValue: '351',
      countValueColor: 'valhalla',
      countSubtitle: 'Employees',
      countSubtitleSmall: true,
    },
  },
  { // 5
    title: '',
    titleColor: '',
    description: '',
    count: {
      countValue: '62',
      countValueColor: 'purple',
      countSubtitle: 'Customers with </br> a water meter',
      countSubtitleSmall: true,
      countSubtitleColor: 'valhalla',
    },
  },
  { // 6
    count: {
      countValue: '160',
      countValueColor: 'valhalla',
      countSubtitle: 'Litres of water supplied every day',
      countSubtitleSmall: true,
    },
  },
  { // 7
    count: {
      countValue: '83',
      countValueColor: 'teal',
      countSubtitle: 'Percentage of our pipe network that is ‘smart’',
      countSubtitleSmall: true,
      countSubtitleColor: 'valhalla',
    },
  },
  { // 8
    topData: {
      count: {
        countValue: '15',
        countValueColor: 'crete',
        countSubtitle: 'of the water we supply comes from our reservoir',
        countSubtitleColor: 'valhalla',
        countSubtitleExtraSmall: true,
      },
    },
    bottomData: {
      count: {
        countValue: '85',
        countValueColor: 'valhalla',
        countSubtitle: 'of the water we supply comes from underground sources',
        countSubtitleExtraSmall: true,
      },
    },
  },
  { // 9
    count: {
      countValue: '8',
      countValueColor: 'orange',
    },
  },
]
