import styled from 'styled-components'

import media from '../../../../common/MediaQueries'
import { StyledAboutNumberSubtitle } from '../SimpleSection/styles'

export const StyledProgressValueSection = styled.div`
  @media ${media.tablet} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const StyledProgressValueSectionInfo = styled.div`
  @media ${media.tablet} {
    width: calc(50% - 15.25rem);
    &:first-child {
      padding-right: 3.25rem;
      text-align: right;
      ${StyledAboutNumberSubtitle} {
        margin-right: 0;
      }
    }
    &:last-child {
      padding-left: 3.25rem;
      text-align: left;
    }
  }
`

export const StyledProgressValueSectionBar = styled.div`
  position: relative;
  width: 11.5rem;
  margin: 1.875rem auto;

  @media ${media.phone} {
    width: 30.5rem;
  }
`

export const StyledProgressValueSectionGraph = styled.div`
  //ie fix
  > div > div {
    height: 11.5rem !important;
  }
  &:last-child {
    position: absolute;
    top: 0;
    width: 100%;
  }
  @media ${media.phone} {
    > div > div {
      height: 30.5rem !important;
      width: 30.5rem !important;
    }
  }
`

export const StyledProgressValueSectionIcon = styled.div`
  svg {
    font-size: 4.875rem;
  }
  @media ${media.phone} {
    svg {
      font-size: 13rem;
    }
  }
`
