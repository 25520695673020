import styled from 'styled-components'

import media from '../../common/MediaQueries'

export const StyledQA = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StyledQAHead = styled.div`
  margin-bottom: .5rem;

  -webkit-text-fill-color: ${({ theme }) => theme.colors.valhalla};
  -webkit-text-stroke-width: .125rem;
  -webkit-text-stroke-color: ${({ theme }) => theme.colors.teal};
`

export const StyledQASubhead = styled.div`
  margin-bottom: 1.5rem;

  @media ${media.phone} {
    margin-bottom: 2.25rem;
  }
`

export const StyledQAMedia = styled.div`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 4.25rem;

  @media ${media.phone} {
    margin-bottom: 5.5.rem;
    width: 11.75rem;
    height: 11.75rem;
  }
`

export const StyledQADesc = styled.div`
  position: relative;
  padding-bottom: 2rem;
  margin-bottom: 2.75rem;
  max-width: 63.125rem;

  &::after {
    position: absolute;
    display: block;
    content: '';
    background-color: ${({ theme }) => theme.colors.white};
    bottom: 0;
    height: 0.125rem;
    width: 10.375rem;
    left: calc(50% - (10.375rem / 2));
  }

  @media ${media.phone} {
    padding-bottom: 3rem;
    margin-bottom: 3.625rem;
  }
`

export const StyledQAPlayer = styled.div`
  margin-bottom: 2.75rem;
  width: 100%;
  max-width: 45rem;

  @media ${media.phone} {
    margin-bottom: 5.625rem;
  }
`

export const StyledQAIframe = styled.iframe`
  width: 100%;
`

export const StyledQAAction = styled.div``
