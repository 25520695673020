import styled from 'styled-components'

import media from '../../../../common/MediaQueries'

const StyledAboutNumber = styled.div`
  &:not(:first-child) {
    margin-top: 2.25rem;
  }
  @media ${media.phone} {
    &:not(:first-child) {
      margin-top: 5.25rem;
    }
  }
`

const StyledAboutTitle = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.25rem;
  margin: 0 auto 1.875rem auto;

  + ${StyledAboutNumber} {
    margin-top: 0;
  }

  > * {
    max-width: 43.75rem;
  }

  @media ${media.phone} {
    margin-bottom: 4rem;
  }
`

const StyledAboutDescription = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  p {
    max-width: 22.5rem;
  }
  @media ${media.phone} {
    p {
      max-width: 40rem// 38.125rem;
    }
  }
`

const StyledAboutNumberSubtitle = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;

  > * {
    max-width: 13.5rem;
  }

  > h3 {
    margin-top: 1.5rem;
  }

  br {
    display: none;
  }

  @media ${media.phone} {
    > * {
      max-width: 32rem;
    }
    > h3 {
      margin-top: 3rem;
    }
  }

  @media ${media.tablet} {
    br {
      display: block;
    }
  }
`

export {
  StyledAboutTitle,
  StyledAboutDescription,
  StyledAboutNumber,
  StyledAboutNumberSubtitle,
}
