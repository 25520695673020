import React from 'react'
import PropTypes from 'prop-types'

import { StyledNavigation } from './styles'
import Report from '../Report'

const Navigation = ({ staticNav }) => (
  <StyledNavigation>
    <Report staticNav={staticNav} />
  </StyledNavigation>
)

export default Navigation

Navigation.propTypes = {
  staticNav: PropTypes.bool,
}
