import styled, { keyframes } from 'styled-components'

import { transition } from '../../../common/mixins'
import media from '../../../common/MediaQueries'

const pulse = keyframes`
    0% {
        transform: translate3d(.5626rem, 0.5rem, 0);
    }
    50% {
        transform: translate3d(.5626rem, 0.375rem, 0);
    }
    100% {
        transform: translate3d(.5626rem, 0.5rem, 0);
    }
`

const StyledDownloadLink = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  width: 100%;
  max-width: 19.25rem;
  color: ${({ theme }) => theme.colors.valhalla};
  background: ${({ theme }) => theme.colors.white};
  min-height: 4.125rem;
  border: 0.125rem solid ${({ theme }) => theme.colors.valhalla};
  padding: 0.75rem 0.5rem 0.625rem 1.75rem;
  ${transition}

  @media ${media.phone} {
    &:hover {
      color: ${({ theme }) => theme.colors.teal};
      border-color: ${({ theme }) => theme.colors.teal};
      svg {
        color: ${({ theme }) => theme.colors.teal};
      }
    }
  }

  svg {
    ${transition};
    font-size: 2.1875rem;
  }

  svg circle {
    display: none;
  }

  &:hover {
    svg {
      .arrow {
        animation-name: ${pulse};
        animation-duration: 1.2s;
        animation-iteration-count: infinite;
      }
    }
  }
`

const StyledDownloadLabel = styled.span`
  margin-right: 0.3125rem;
  font-weight: 500;
`

const StyledDownloadLinkSize = styled.span`
  margin-top: 0.25rem;
  display: block;
`

export { StyledDownloadLink, StyledDownloadLabel, StyledDownloadLinkSize }
