import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledHeading,
  StyledHeadingTitle,
  StyledHeadingSubtitle,
} from './styles'
import { Heading2, Heading6 } from '../Typography'

const Heading = ({ title, subtitle, color }) => (
  <StyledHeading style={{ color: color}}>
    {!!title ? (
      <StyledHeadingTitle>
        <Heading2 {...{ color }}>{title}</Heading2>
      </StyledHeadingTitle>
    ) : (
      ''
    )}
    {!!subtitle ? (
      <StyledHeadingSubtitle>
        <Heading6 {...{ color }}>{subtitle}</Heading6>
      </StyledHeadingSubtitle>
    ) : (
      ''
    )}
  </StyledHeading>
)

export default Heading

Heading.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  color: PropTypes.string,
}
