import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'

import {
  StyledDownload,
  StyledDownloadHead,
  StyledDownloadListItem,
  StyledDownloadSliderWrapper,
  StyledDownloadSlider,
  StyledDownloadBox,
  StyledDownloadBoxTitle,
  StyledDownloadBoxMedia,
} from './styles'
import { downloadsMock } from './mock'
import { Typo1 } from '../Typography'
import { PrevButton, NextButton } from '../SliderNavigation'
import theme from '../../themes/theme'

import Heading from '../Heading'

const sliderDetailsOptions = {
  arrows: true,
  prevArrow: <PrevButton isAlt />,
  nextArrow: <NextButton isAlt />,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 1023,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

const Downloads = () => {
  const {
    images: { edges: downloads },
  } = useStaticQuery(
    graphql`
      query {
        images: allImageSharp(
          filter: { original: { src: { regex: "/img-downloads/" } } }
          sort: { fields: original___src }
        ) {
          edges {
            node {
              id
              fluid(maxWidth: 446, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    `,
  )

  const downloadsImages = downloads.map(({ node: { id, fluid } }) => {
    return { id, fluid }
  })

  const downloadBoxes = new Array(downloadsMock.length)
    .fill('')
    .map((_, i) => {
      return { ...downloadsImages[i], ...downloadsMock[i] }
    })

  return (
    <StyledDownload>
      <StyledDownloadHead>
        <Heading title="Downloads" color={theme.colors.valhalla} />
      </StyledDownloadHead>

      <StyledDownloadSliderWrapper>
        <StyledDownloadSlider {...sliderDetailsOptions}>
          {downloadBoxes.map(({ id, fluid, title, fileExtension, url }) => (
            <StyledDownloadListItem key={id}>
              <StyledDownloadBox
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={title}
              >
                <StyledDownloadBoxMedia>
                  <Img {...{ fluid }} />
                </StyledDownloadBoxMedia>
                <StyledDownloadBoxTitle>
                  <Typo1
                    letterSpacing="-1px"
                    lineHeight="1.4"
                    fontWeight="medium"
                    textAlign="center"
                    color="valhalla"
                  >
                    {title} <span>{fileExtension}</span>
                  </Typo1>
                </StyledDownloadBoxTitle>
              </StyledDownloadBox>
            </StyledDownloadListItem>
          ))}
        </StyledDownloadSlider>
      </StyledDownloadSliderWrapper>
    </StyledDownload>
  )
}

export default Downloads
