import React from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'

import {
  StyledAimDetails,
  StyledAimDetailsAside,
  StyledAimDetailsAsideInner,
  StyledAimDetailsAsideHead,
  StyledAimDetailsAsideTitle,
  StyledAimDetailsAsideDesc,
  StyledAimDetailsMain,
  StyledAimDetailsContent,
  StyledAimDetailsInner,
  StyledAimDetailsIcon,
  StyledAimDetailsIconBox,
} from './styles'
import Container from '../../Container'
import { Heading2, Heading11, Heading13, Typo16 } from '../../Typography'
import Measures from '../../Measures'

const AimDetails = ({
  bg,
  bgDetails,
  teaserTitle,
  title,
  desc,
  measures,
  icon,
}) => (
  <StyledAimDetails bg={bg}>
    <StyledAimDetailsContent>
      <StyledAimDetailsAside>
        <StyledAimDetailsAsideInner>
          <StyledAimDetailsAsideHead>
            <Container mb={['2rem', '2.5rem']}>
              <StyledAimDetailsIconBox>
                <StyledAimDetailsIcon src={icon} alt="pledge" />
              </StyledAimDetailsIconBox>
              <Heading11 as="h4" fontWeight="bold" textTrasnform="uppercase">
                {teaserTitle}
              </Heading11>
            </Container>
          </StyledAimDetailsAsideHead>
          <StyledAimDetailsAsideTitle>
            <Heading2 as="h3" fontWeight="bold">{ReactHtmlParser(title)}</Heading2>
          </StyledAimDetailsAsideTitle>
          {desc && (
            <StyledAimDetailsAsideDesc>
              <Container mb={["1.75rem", "1.25rem"]}>
                <Typo16 as="h4" fontWeight="bold">
                  Our pledge in action
                </Typo16>
              </Container>
              <Heading13 fontWeight="medium">{ReactHtmlParser(desc)}</Heading13>
            </StyledAimDetailsAsideDesc>
          )}
        </StyledAimDetailsAsideInner>
      </StyledAimDetailsAside>
      <StyledAimDetailsMain bg={bgDetails}>
        <StyledAimDetailsInner bg={bg}>
          <Container pr={['', '1rem']}>
            <Container mb="0.5rem">
              <Heading11 as="h4" fontWeight="bold">
                Our measures
              </Heading11>
            </Container>
            <Container>
              <Measures measures={measures} />
            </Container>
          </Container>
        </StyledAimDetailsInner>
      </StyledAimDetailsMain>
    </StyledAimDetailsContent>
  </StyledAimDetails>
)

export default AimDetails

AimDetails.propTypes = {
  id: PropTypes.number,
  bg: PropTypes.string,
  bgDetails: PropTypes.string,
  head: PropTypes.string,
  icon: PropTypes.string,
  measures: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          subtitle: PropTypes.string,
        })
      ),
    }),
  ),
}
