import styled from 'styled-components'

import media from '../../../../common/MediaQueries'
import { StyledAboutDescription } from '../SimpleSection/styles'

export const StyledAboutImgMap = styled.div`
  @media ${media.phone} {
    max-height: 23.125rem;
  }
`

export const StyledAboutColBoxMap = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media ${media.phone} {
    flex-direction: row;
  }
`

export const StyledAboutColMap = styled.div`
  &:not(:first-child) {
    margin-bottom: 2rem;
  }

  ${StyledAboutDescription} {
    text-align: center;
    margin: 0 auto;
    max-width: 25rem;
  }

  @media ${media.phone} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    padding: 0 3.625rem 0 0;

    ${StyledAboutDescription} {
      text-align: right;
      margin-right: 0;
    }

    &:not(:first-child) {
      margin-bottom: 0;
      padding: 0;
    }
  }
`
