import styled, { css } from 'styled-components'

import media from '../../common/MediaQueries'
import Container from '../Container'
import { Typo18, Typo8 } from '../Typography'

const StyledMeasures = styled.div`
  margin-top: 1.5rem;

  @media ${media.phone} {
    margin-top: 2.5rem;
  }
`

const StyledMeasuresDecor = styled.span`
  margin-right: 1rem;
  height: 1.5rem;
  width: 1.5rem;
  min-width: 1.5rem;
  background-color: ${({ theme }) => theme.colors.crete};
  border: 0.125rem solid ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  ${({ isAlt }) =>
    isAlt &&
    css`
      background-color: ${({ theme }) => theme.colors.orange};
    `};
  @media ${media.phone} {
    height: 2rem;
    width: 2rem;
    min-width: 2rem;
  }
`

const StyledMeasuresRow = styled.div`
  padding-bottom: 2.375rem;
  &:not(:last-child) {
    margin-bottom: 2.5rem;
    border-bottom: 0.125rem solid ${({ theme }) => theme.colors.white};
  }
`

const StyledMeasuresHead = styled.div`
  display: flex;
  margin-bottom: 1.5rem;

  @media ${media.phone} {
    margin-bottom: 2rem;
  }
`

const StyledMeasureHeadTypo = styled(Container)`
  max-width: 23rem;
  sub {
    vertical-align: sub;
    font-size: 0.875rem;
  }
  span {
    word-break: break-word;
    font-weight: ${({ theme }) => theme.fontWeights.book};
  }
  .alt-spacing {
    word-spacing: -0.375rem;
    max-width: 20.625rem;
    display: block;
  }
`

const StyledMeasuresList = styled.ul`
  @media ${media.phone} {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 1rem);
    margin-top: -0.5rem;
    margin-left: -0.5rem;
  }
`

const StyledMeasuresListItem = styled.li`
  display: flex;
  flex-direction: column;

  &:not(:first-child) {
    padding-top: 1.5rem;
  }

  @media ${media.phone} {
    width: 50%;
    padding: 0.5rem 0.5rem 0;
    &:not(:first-child) {
      padding-top: 0.5rem;
    }
  }
`

const StyledMeasuresListSubtitle = styled(Typo18)`
  sup {
    vertical-align: super;
    font-size: 1.25rem;
  }
  sub {
    vertical-align: sub;
    font-size: 1.25rem;
  }
  @media ${media.phone} {
    margin-top: auto;
    word-break: break-word;
    span {
      display: block;
      font-weight: ${({ theme }) => theme.fontWeights.book};
      font-size: 2.25rem;
      margin-top: auto;
    }
    sub,
    sup {
      font-size: 1.5rem;
    }
  }
`

const StyledMeasuresScript = styled(Typo8)`
  margin-top: 2rem;
  span {
    font-weight: 700;
  }
`

export {
  StyledMeasures,
  StyledMeasuresHead,
  StyledMeasuresRow,
  StyledMeasuresList,
  StyledMeasuresListItem,
  StyledMeasuresDecor,
  StyledMeasureHeadTypo,
  StyledMeasuresListSubtitle,
  StyledMeasuresScript,
}
