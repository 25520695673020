import React from 'react'
import PropTypes from 'prop-types'

import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import AnimatedProgressProvider from './vendor'

const ProgressBar = ({
  state,
  value,
  valueStart,
  pathWidth,
  children,
  config,
  directionLeft,
}) => {
  return (
    <AnimatedProgressProvider
      valueStart={valueStart ? valueStart : 0}
      valueEnd={state ? value : valueStart ? valueStart : 0}
      duration={0.8}
    >
      {(percentage) => (
        <CircularProgressbarWithChildren
          value={percentage}
          styles={buildStyles({ ...config })}
          strokeWidth={pathWidth || 8}
          counterClockwise={directionLeft ? true : false}
        >
          {children}
        </CircularProgressbarWithChildren>
      )}
    </AnimatedProgressProvider>
  )
}

export default ProgressBar

ProgressBar.propTypes = {
  state: PropTypes.bool,
  value: PropTypes.number || PropTypes.string,
  valueStart: PropTypes.number || PropTypes.string,
  pathWidth: PropTypes.number || PropTypes.string,
  children: PropTypes.node,
  config: PropTypes.object,
  directionLeft: PropTypes.bool
}
