import React from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'

import {
  StyledDownloadLink,
  StyledDownloadLabel,
  StyledDownloadLinkSize,
} from './styles'
import DownloadIcon from '../../Icons/DownloadIcon'
import { Typo4, Typo8 } from '../../Typography'

const DownloadLink = ({ url, label, size }) => (
  <StyledDownloadLink href={url} target="_blank" rel="noopener noreferrer">
    <StyledDownloadLabel>
      <Typo8 lineHeight="1.2">{ReactHtmlParser(label)}</Typo8>
      {size && (
        <StyledDownloadLinkSize>
          <Typo4>PDF {size}</Typo4>
        </StyledDownloadLinkSize>
      )}
    </StyledDownloadLabel>
    <DownloadIcon />
  </StyledDownloadLink>
)

export default DownloadLink

DownloadLink.propTypes = {
  url: PropTypes.string,
  size: PropTypes.string,
  label: PropTypes.string,
}
