import React from 'react'

import ArrowLeftIcon from '../Icons/ArrowLeftIcon'
import ArrowRightIcon from '../Icons/ArrowRightIcon'
import ArrowSliderIcon from '../Icons/ArrowSliderIcon'
import {
  StyledSliderNavigationPrev,
  StyledSliderNavigationNext,
} from './styles'

export const PrevButton = ({ className, onClick, isAlt }) => (
  <StyledSliderNavigationPrev
    aria-label="prev"
    className={className}
    {...{ onClick }}
  >
    {!isAlt ? (
      <ArrowLeftIcon />
    ) : (
      <ArrowSliderIcon />
    )}
  </StyledSliderNavigationPrev>
)

export const NextButton = ({ className, onClick, isAlt }) => (
  <StyledSliderNavigationNext
    aria-label="next"
    className={className}
    {...{ onClick }}
  >
    {!isAlt ? (
      <ArrowRightIcon />
    ) : (
      <ArrowSliderIcon />
    )}
  </StyledSliderNavigationNext>
)
