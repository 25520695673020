import React from 'react'

import { IconWrapper } from '../../common/mixins'

const DropIcon = () => (
  <IconWrapper>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="514px"
      height="505px"
      viewBox="0 0 514 505"
    >
      <defs>
        <polygon id="path-1" points="0 0 107 0 107 108 0 108" />
        <polygon id="path-3" points="0 0 107 0 107 108 0 108" />
        <polygon id="path-5" points="0 0 107 0 107 108 0 108" />
        <polygon id="path-7" points="0 0 107 0 107 107 0 107" />
        <polygon id="path-9" points="0 0 107 0 107 107 0 107" />
        <polygon id="path-11" points="0 0 107 0 107 108 0 108" />
        <polygon id="path-13" points="0 0 108 0 108 106 0 106" />
        <polygon id="path-15" points="0 0 107 0 107 106 0 106" />
      </defs>
      <g
        id="Desktop"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="SES-Water-AR-Desktop-"
          transform="translate(-202.000000, -8675.000000)"
        >
          <g
            className="circles-icons"
            id="Group-12"
            transform="translate(202.000000, 8675.000000)"
          >
            <g id="icon-7" transform="translate(202.000000, 0.000000)">
              <path
                d="M53.5,108 C83.0475993,108 107,83.8237451 107,54 C107,24.1762549 83.0475993,0 53.5,0 C23.9524007,0 0,24.1762549 0,54 C0,83.8237451 23.9524007,108 53.5,108"
                id="Fill-1"
                fill="#211F5E"
                mask="url(#mask-2)"
              />
            </g>
            <g
              id="icon-3"
              transform="translate(202.000000, 397.000000)"
            >
              <path
                d="M53.5,108 C83.0475993,108 107,83.8237451 107,54 C107,24.1762549 83.0475993,0 53.5,0 C23.9524007,0 0,24.1762549 0,54 C0,83.8237451 23.9524007,108 53.5,108"
                id="Fill-1"
                fill="#211F5E"
              />
            </g>
            <g
              id="icon-2"
              transform="translate(346.000000, 341.000000)"
            >
              <path
                d="M53.5,108 C83.0475993,108 107,83.8237451 107,54 C107,24.1762549 83.0475993,0 53.5,0 C23.9524007,0 0,24.1762549 0,54 C0,83.8237451 23.9524007,108 53.5,108"
                id="Fill-1"
                fill="#211F5E"
              />
            </g>
            <g id="icon-4" transform="translate(58.000000, 341.000000)">
              <path
                d="M53.5,107 C83.0475993,107 107,83.0475993 107,53.5 C107,23.9524007 83.0475993,0 53.5,0 C23.9524007,0 0,23.9524007 0,53.5 C0,83.0475993 23.9524007,107 53.5,107"
                id="Fill-1"
                fill="#211F5E"
              />
            </g>
            <g
              id="icon-1"
              transform="translate(407.000000, 200.000000)"
            >
              <path
                d="M53.5,107 C83.0475993,107 107,83.0475993 107,53.5 C107,23.9524007 83.0475993,0 53.5,0 C23.9524007,0 0,23.9524007 0,53.5 C0,83.0475993 23.9524007,107 53.5,107"
                id="Fill-1"
                fill="#211F5E"
              />
            </g>
            <g id="icon-5" transform="translate(0.000000, 199.000000)">
              <path
                d="M53.5,108 C83.0475993,108 107,83.8237451 107,54 C107,24.1762549 83.0475993,0 53.5,0 C23.9524007,0 0,24.1762549 0,54 C0,83.8237451 23.9524007,108 53.5,108"
                id="Fill-1"
                fill="#211F5E"
              />
            </g>
            <g id="Group-3-Copy-9" transform="translate(346.000000, 58.000000)">
              <path
                d="M54,106 C83.8237451,106 108,82.2714535 108,53 C108,23.7285465 83.8237451,0 54,0 C24.1762549,0 0,23.7285465 0,53 C0,82.2714535 24.1762549,106 54,106"
                id="Fill-1"
                fill="#E03F10"
              />
            </g>
            <g id="icon-6" transform="translate(58.000000, 58.000000)">
              <path
                d="M53.5,106 C83.0475993,106 107,82.2714535 107,53 C107,23.7285465 83.0475993,0 53.5,0 C23.9524007,0 0,23.7285465 0,53 C0,82.2714535 23.9524007,106 53.5,106"
                id="Fill-1"
                fill="#211F5E"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </IconWrapper>
)

export default DropIcon
