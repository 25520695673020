import React from 'react'

import { VideoExternalStyled } from './styles'

const Video = ({ altText, videoUrl, videoId }) => {
  let externalVideoId = videoId || ''
  const isVimeo = videoUrl.includes('vimeo')

  if (videoUrl && videoUrl.includes('//vimeo.com/')) {
    externalVideoId = videoUrl.split('//vimeo.com/')[1].split('/')[0]
  } else if (videoUrl && videoUrl.includes('vimeo.com/video/')) {
    externalVideoId = videoUrl.split('.vimeo.com/video/')[1]
  } else if (videoUrl && videoUrl.includes('youtu.be/')){
    externalVideoId = videoUrl.split('youtu.be/')[1]
  }

  const iframeSrc = isVimeo ? `https://player.vimeo.com/video/${externalVideoId}?background=0&autoplay=1` : `https://www.youtube.com/embed/${externalVideoId}?autoplay=1`

  return (
    <>
      <VideoExternalStyled
        src={iframeSrc}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
        autoplay
        title={altText}
        loading="lazy"
      />
    </>
  )
}

export default Video
