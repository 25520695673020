import styled from 'styled-components'

import { transition } from '../../common/mixins'
import media from '../../common/MediaQueries'

const StyledSliderNavigation = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  z-index: 1;
  ${transition}

  svg {
    font-size: 3.125rem;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.teal};
  }

  &.slick-disabled {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
`

const StyledSliderNavigationPrev = styled(StyledSliderNavigation)`
  left: -3.25rem;

  @media ${media.desktop} {
    left: -7.25rem;
  }
`

const StyledSliderNavigationNext = styled(StyledSliderNavigation)`
  right: -3.25rem;

  @media ${media.desktop} {
    right: -7.25rem;
  }
`

export { StyledSliderNavigationPrev, StyledSliderNavigationNext }
