import styled from 'styled-components'

const StyledNavigation = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
`

export { StyledNavigation }
