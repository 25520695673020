import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import Img from 'gatsby-image/withIEPolyfill'

import { StyledAboutTile, StyledAboutImg } from '../../styles'
import { StyledAboutDescription } from '../SimpleSection/styles'
import {
  StyledAboutImgMap,
  StyledAboutColBoxMap,
  StyledAboutColMap,
} from './styles'
import { Typo11 } from '../../../Typography'

const MapSection = ({ description, image }) => {
  return (
    <StyledAboutTile>
      <StyledAboutColBoxMap>
        <StyledAboutColMap>
          <Fade left delay={500}>
            <StyledAboutDescription>
              <Typo11 color="valhalla" fontWeight="bold">
                {description}
              </Typo11>
            </StyledAboutDescription>
          </Fade>
        </StyledAboutColMap>
        <StyledAboutColMap>
          <Fade right delay={500}>
            <StyledAboutImgMap>
              <StyledAboutImg>
                <Img fluid={image} objectFit="contain" objectPosition="0 0" />
              </StyledAboutImg>
            </StyledAboutImgMap>
          </Fade>
        </StyledAboutColMap>
      </StyledAboutColBoxMap>
    </StyledAboutTile>
  )
}

export default MapSection

MapSection.propTypes = {
  description: PropTypes.string,
  image: PropTypes.string,
}
