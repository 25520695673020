import React, { useState } from 'react'

import {
  StyledValues,
  StyledValuesInner,
  StyledValuesHeader,
  StyledValuesTitle,
  StyledValuesSecondTitle,
  StyledValuesInfo,
  StyledValuesContent,
  StyledValuesIcon,
  StyledValuesIconPath,
  StyledValuesTab,
  StyledValuesTabContent,
  StyledValuesTabDesc,
  StyledValuesIconImg,
  StyledValuesValue,
} from './styles'
import { Heading3, Typo2, Typo6 } from '../Typography'
import { IconWrapper } from '../../common/mixins'
import { values } from './mock'
import Heading from '../Heading'

const Values = () => {
  const [active, setActive] = useState('investment')

  const handleAction = (e) => {
    setActive(e.currentTarget.id)
  }

  return (
    <StyledValues>
      <StyledValuesInner>
        <StyledValuesHeader>
          <StyledValuesTitle>
            <Heading title="The value we share" color="valhalla" />
          </StyledValuesTitle>
          <StyledValuesSecondTitle>
            <Heading3 color="teal">
              The average household in our supply area spends just over 50p a
              day on their water supply.
            </Heading3>
          </StyledValuesSecondTitle>
          <StyledValuesInfo>
            <Typo2 color="navyBlue">
              <span className="desktop">Rollover</span>
              <span className="mobile">Click</span> the chart to find out where
              it goes:
            </Typo2>
          </StyledValuesInfo>
        </StyledValuesHeader>
        <StyledValuesContent>
          <StyledValuesIcon>
            <IconWrapper>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
                <StyledValuesIconPath
                  d="M280.6 183.8c-54.3 30-96.1 79.8-115.5 139.7l-96.6-31.4c28.6-88 81.8-152.6 162.8-197.4l49.3 89.1z"
                  id="financing"
                  onClick={handleAction}
                  onMouseEnter={handleAction}
                  active={active === 'financing'}
                />
                <StyledValuesIconPath
                  d="M210.9 559L133 624.4C53.7 529.9 30.3 409.5 68.4 292.1l96.6 31.4c-7.9 24.1-12.1 49.8-12.1 76.5 0 60.6 21.8 116.1 58 159z"
                  id="employees"
                  onClick={handleAction}
                  onMouseEnter={handleAction}
                  active={active === 'employees'}
                />
                <StyledValuesIconPath
                  d="M696.5 585C594.3 748.5 379 798.3 215.4 696.1c-32.7-20.4-57.7-42.2-82.4-71.7l77.9-65.4c45.3 53.9 113.2 88.1 189.1 88.1 88.5 0 166.1-46.5 209.7-116.4l86.8 54.3z"
                  id="energy"
                  onClick={handleAction}
                  onMouseEnter={handleAction}
                  active={active === 'energy'}
                />
                <StyledValuesIconPath
                  d="M400.4 50.9V153h-.4c-43.3 0-84 11.2-119.4 30.8l-49.4-89.1c53.9-29.9 107.5-43.8 169.2-43.8z"
                  id="tax"
                  onClick={handleAction}
                  onMouseEnter={handleAction}
                  active={active === 'tax'}
                />
                <StyledValuesIconPath
                  d="M749.4 400.1c0 69.4-16.3 126.1-53 185l-86.8-54.3c23.7-37.9 37.4-82.7 37.4-130.7 0-136.3-110.4-246.9-246.6-247.1V51c192.7 0 349 156.3 349 349.1z"
                  id="investment"
                  onClick={handleAction}
                  onMouseEnter={handleAction}
                  active={active === 'investment'}
                />
              </svg>
            </IconWrapper>
            {values.map(({ id, title, icon }) => (
              <StyledValuesIconImg
                active={active === id}
                key={title}
                top={icon.position.top}
                right={icon.position.right}
                bottom={icon.position.bottom}
                left={icon.position.left}
              >
                <img src={icon.img} alt={title} />
              </StyledValuesIconImg>
            ))}
          </StyledValuesIcon>

          {values.map(({ id, title, desc, value, position, icon }) => (
            <StyledValuesTab active={active === id} key={title}>
              <StyledValuesTabDesc
                top={position.top}
                right={position.right}
                bottom={position.bottom}
                left={position.left}
              >
                <img src={icon.img} alt={title} />
                <Typo6 color="navyBlue">{desc}</Typo6>
              </StyledValuesTabDesc>

              <StyledValuesTabContent>
                <StyledValuesValue>{value}</StyledValuesValue>
                <Typo2 color="navyBlue" fontWeight="bold">
                  {title}
                </Typo2>
              </StyledValuesTabContent>
            </StyledValuesTab>
          ))}
        </StyledValuesContent>
      </StyledValuesInner>
    </StyledValues>
  )
}

export default Values
