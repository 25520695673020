import styled, { css } from 'styled-components'

import media from '../../../../common/MediaQueries'
import { transition } from '../../../../common/mixins'

export const StyledDropSection = styled.div`
  margin-bottom: 1.25rem;

  svg {
    font-size: 11rem;
    width: 0.66em;
  }

  svg #drop-fill-alt,
  svg #drop-fill {
    ${transition};
    transition-delay: 300ms;
  }

  ${({ isVisible }) =>
    isVisible &&
    css`
      svg #drop-fill,
      svg #drop-fill-alt {
        fill: ${({ theme }) => theme.colors.valhalla};
      }
    `};

  @media ${media.phone} {
    margin-bottom: 3.5rem;
    svg {
      font-size: 26rem;
    }
  }
`
