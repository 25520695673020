import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image/withIEPolyfill'
import ReactHtmlParser from 'react-html-parser'

import {
  StyledAimTeaser,
  StyledAimTitle,
  StyledAimTeaserContent,
  StyledAimTeaserContentInner,
  StyledAimTeaserMedia,
  StyledAimTeaserImg,
  StyledAimTeaserAction,
  StyledAimTeaserWrapper,
  StyledAimTeaserActionMobile,
  StyledAimTeaserActionDesktop,
  StyledAimTeaserIconBox,
  StyledAimTeaserIcon,
} from './styles'
import { Heading11, Heading12 } from '../../Typography'
import { fluidPropType } from '../../../constants/proptypes'
import NavigationLink from '../../Links/NavigationLink'

const AimTeaser = ({
  title,
  fluidBigImage,
  icon,
  bg,
  teaserTitle,
  handleClick,
}) => {
  return (
    <StyledAimTeaserWrapper>
      <StyledAimTeaser bg="white" onClick={handleClick}>
        {fluidBigImage && (
          <StyledAimTeaserMedia>
            <StyledAimTeaserImg>
              <Img fluid={fluidBigImage} objectFit="cover" />
            </StyledAimTeaserImg>
          </StyledAimTeaserMedia>
        )}
        <StyledAimTeaserContent color={bg}>
          <StyledAimTeaserContentInner>
          {icon && (
            <StyledAimTeaserIconBox>
            <StyledAimTeaserIcon src={icon} alt="pledge" />
          </StyledAimTeaserIconBox>
          )}
          <StyledAimTitle>
            <Heading11 as="h3" fontWeight="bold" textTransform="uppercase">
              {teaserTitle}
            </Heading11>
          </StyledAimTitle>
          <StyledAimTeaserAction>
            <StyledAimTeaserActionMobile>
              <NavigationLink color="navyBlue" label="read more" />
            </StyledAimTeaserActionMobile>
            <StyledAimTeaserActionDesktop>
              <NavigationLink
                color="navyBlue"
                label="read more"
                inverted={true}
              />
            </StyledAimTeaserActionDesktop>
          </StyledAimTeaserAction>
          <Heading12 as="h4" fontWeight="bold">
            {ReactHtmlParser(title)}
          </Heading12>
          </StyledAimTeaserContentInner>
        </StyledAimTeaserContent>
      </StyledAimTeaser>
    </StyledAimTeaserWrapper>
  )
}

export default AimTeaser

AimTeaser.propTypes = {
  bg: PropTypes.string,
  teaserTitle: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  fluidBigImage: fluidPropType,
  measures: PropTypes.arrayOf(
    PropTypes.shape({
      head: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          subtitle: PropTypes.string,
        })
      ),
      isAlt: PropTypes.bool,
    }),
  ),
}
