import React, { useState } from 'react'
import PropTypes from 'prop-types'
import VisibilitySensor from 'react-visibility-sensor'

import SimpleSection from '../SimpleSection'
import { StyledDropSection } from './styles'
import DropIcon from '../../../Icons/DropIcon'
import DropIconAlt from '../../../Icons/DropIconAlt'

const DropSection = ({ count, afterfix, isAlt }) => {
  const [isVisible, setIsVisible] = useState(false)

  const onVisibilityChange = (visibility) => {
    if (visibility) {
      setIsVisible(true)
    }
  }

  return (
    <VisibilitySensor
      onChange={onVisibilityChange}
      offset={{
        top: 100,
      }}
      delayedCall
      resizeCheck
      scrollCheck
    >
      <>
        <StyledDropSection {...{ isVisible }}>
          {isAlt ? <DropIconAlt /> : <DropIcon />}
        </StyledDropSection>
        <SimpleSection {...{ count, afterfix }} />
      </>
    </VisibilitySensor>
  )
}

export default DropSection

SimpleSection.propTypes = {
  count: PropTypes.string,
  afterfix: PropTypes.string,
  isAlt: PropTypes.bool,
}
