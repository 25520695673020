import React, { useState } from 'react'

import HeroPlaceholder from '../../assets/hero-placeholder-ses-v2.gif'
import {
  StyledHero,
  StyledHeroMedia,
  StyledHeroImg,
  StyledHeroContent,
  StyledHeroInfo,
  StyledHeroPlayBox,
  StyledHeroPlay,
  StyledHeroHead,
  StyledHeroSubhead,
  StyledHeroScroll,
  StyledHeroOverlay,
  StyledHeroLoader,
  StyledHeroClose,
} from './styles'
import NavigationLink from '../Links/NavigationLink'
import { URL_PATHS } from '../../constants/urlPaths'
import { matchMedia } from '../../common/utils'
import media from '../../common/MediaQueries'
import ArrowDownIcon from '../Icons/ArrowDownIcon'
import CloseIcon from '../Icons/CloseIcon'
import LoaderIcon from '../Icons/LoaderIcon'
import Video from '../Video'

const Hero = () => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false)
  const abovePhone = matchMedia(`${media.phone}`).matches

  const handleClick = (e) => {
    e.preventDefault()
    setIsVideoPlaying(!isVideoPlaying)
  }

  return (
    <StyledHero>
      <StyledHeroMedia>
        <StyledHeroOverlay />
        <StyledHeroImg src={HeroPlaceholder} alt="hero" />
      </StyledHeroMedia>

      {isVideoPlaying ? (
        <StyledHeroMedia isAlt {...{ isVideoPlaying }}>
          <StyledHeroClose href="#close-the-video" onClick={handleClick}>
            <CloseIcon />
          </StyledHeroClose>
          <StyledHeroLoader>
            <LoaderIcon />
          </StyledHeroLoader>
          <Video
            altText="Video SES Water"
            videoUrl="https://vimeo.com/570226572"
          />
        </StyledHeroMedia>
      ) : null}

      <StyledHeroContent isHidden={isVideoPlaying}>
        <StyledHeroInfo>
          <StyledHeroHead as="h1" fontWeight="medium" color="white">
            Rising to <br /> the challenge
          </StyledHeroHead>
          <StyledHeroSubhead as="p" fontWeight="medium" color="white">
            Annual Report and <br /> Financial Statements 2021
          </StyledHeroSubhead>
        </StyledHeroInfo>

        <StyledHeroPlayBox>
          <StyledHeroPlay href="#watch-the-video" onClick={handleClick}>
            <NavigationLink color="white" label="Watch the full video" />
          </StyledHeroPlay>
        </StyledHeroPlayBox>

        <StyledHeroScroll
          to={URL_PATHS.REPORT}
          href={`#${URL_PATHS.REPORT}`}
          spy
          smooth
          delay={100}
          offset={abovePhone ? -80 : -72}
        >
          <ArrowDownIcon />
        </StyledHeroScroll>
      </StyledHeroContent>
    </StyledHero>
  )
}

export default Hero
