import React from 'react'

import { IconWrapper } from '../../common/mixins'

const ArrowDownIcon = () => (
  <IconWrapper>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="118"
      height="44"
      viewBox="0 0 118 44"
    >
      <g stroke="#FFF" fill="none" fillRule="evenodd" strokeLinecap="square">
        <path d="M2.758 2.235L59 41.765M115.242 2.235L59 41.765" />
      </g>
    </svg>
  </IconWrapper>
)

export default ArrowDownIcon
