import styled, { css } from 'styled-components'

import { transition } from '../../../common/mixins'
import Container from '../../Container'

const StyledNavigationLink = styled(Container)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ${transition}

  svg {
    font-size: 1.375rem;
  }

  ${({ isAlignedStart }) =>
    isAlignedStart &&
    css`
      align-items: flex-start;
    `};
`

const StyledNavigationLinkIcon = styled.span``

const StyledNavigationLinkLabel = styled.span`
  margin-left: 1rem;
  z-index: 1;

  &:hover {
    text-decoration: underline;
  }
`

export {
  StyledNavigationLink,
  StyledNavigationLinkLabel,
  StyledNavigationLinkIcon,
}
