import styled, { css } from 'styled-components'

import media from '../../../common/MediaQueries'
import Container from '../../Container'

const StyledAimDetailsIcon = styled.img`
  width: 3.5rem;
  height: 3.5rem;
  border: 0.125rem solid ${({ theme }) => theme.colors.white};
  border-radius: 0.625rem;

  @media ${media.phone} {
    width: 5.8125rem;
    height: 5.8125rem;
    border-width: 0.3125rem;
    border-radius: 1.5rem;
  }
`

const StyledAimDetails = styled(Container)`
  display: flex;
  position: relative;
  height: 100%;
  overflow: hidden;
  @media ${media.phone} {
    overflow-y: auto;
    overflow-x: hidden;
  }
`

const StyledAimDetailsAside = styled.div`
  position: relative;
  width: 100%;
  flex-shrink: 0;
  padding: 1.875rem 1.375rem 6.25rem;

  @media ${media.phone} {
    padding: 6rem 1.875rem 2rem 1.875rem;
    width: 50%;
  }
`

const StyledAimDetailsAsideInner = styled.div`
  position: relative;
  z-index: 1;
`
const StyledAimDetailsAsideHead = styled.div`
  text-transform: uppercase;
  @media (min-width: 75rem) {
    margin-bottom: 2.75rem;
  }
`

const StyledAimDetailsAsideTitle = styled.div``

const StyledAimDetailsAsideDesc = styled.div`
  margin-top: 2.5rem;
  @media ${media.phone} {
    margin: 4rem 0 0 0;
  }
`

const StyledAimDetailsMain = styled(Container)`
  position: relative;
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
  padding: 3.125rem 1.375rem 1.875rem;

  &::after {
    position: absolute;
    display: block;
    content: '';
    background-color: inherit;
    height: 100%;
    width: 100vw;
    right: -100vw;
    top: 0;
  }

  @media ${media.phone} {
    width: 50%;
    padding: 4.75rem 1.875rem 1.875rem 3.75rem
  }
`

const StyledAimDetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.white};
  z-index: 1;
  width: 100%;
  height: 100%;
  max-width: 75.625rem;
  margin: 0 auto;
  overflow-y: auto;
  overflow-x: hidden;

  @media ${media.phone} {
    flex-direction: row;
    overflow-y: initial;
    overflow-x: initial;
  }
`

const StyledAimDetailsInner = styled.div`
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 1rem;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background-color: ${({ theme }) => theme.colors.white};
  }

  &::-webkit-scrollbar-track {
    border-radius: 0.5rem;
    ${({ bg }) =>
      bg === 'valhalla' &&
      css`
        background: ${({ theme }) => theme.colors.valhalla};
      `}

    ${({ bg }) =>
      bg === 'orange' &&
      css`
        background: ${({ theme }) => theme.colors.orange};
      `}

    ${({ bg }) =>
      bg === 'tealDark' &&
      css`
        background: ${({ theme }) => theme.colors.tealDark};
      `}

    ${({ bg }) =>
      bg === 'plum' &&
      css`
        background: ${({ theme }) => theme.colors.plum};
      `}

    ${({ bg }) =>
      bg === 'greenLight' &&
      css`
        background: ${({ theme }) => theme.colors.greenLight};
      `}

    ${({ bg }) =>
      bg === 'green' &&
      css`
        background: ${({ theme }) => theme.colors.green};
      `}
  }

  @media ${media.phone} {
    padding: 0;
    max-height: calc(100vh - 10rem);
    height: calc(100vh - 10rem);
  }
`

const StyledAimDetailsIconBox = styled.div`
  margin-bottom: 2rem;
`

export {
  StyledAimDetails,
  StyledAimDetailsAside,
  StyledAimDetailsAsideInner,
  StyledAimDetailsAsideHead,
  StyledAimDetailsAsideTitle,
  StyledAimDetailsAsideDesc,
  StyledAimDetailsMain,
  StyledAimDetailsContent,
  StyledAimDetailsInner,
  StyledAimDetailsIconBox,
  StyledAimDetailsIcon,
}
