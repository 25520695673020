import styled, { css } from 'styled-components'

import media, { customMedia } from '../../common/MediaQueries'

const StyledAbout = styled.div``

const StyledAboutList = styled.ul`
  margin-top: -2rem;
  @media ${media.phone} {
    margin-top: -4rem;
  }
`

const StyledAboutItem = styled.li`
  position: relative;
  flex: 1;
  padding-bottom: 9.75rem;
  padding-bottom: 12.125rem;
  margin-top: 2rem;

  &::after {
    position: absolute;
    display: block;
    content: '';
    bottom: 0;
    left: calc(50% - 1px);
    width: 0.125rem;
    height: 10.25rem;
    background-color: ${({ theme }) => theme.colors.valhalla};
  }

  ${({ isMap }) =>
    isMap &&
    css`
      @media ${media.phone} {
        padding-bottom: 6.375rem;
        margin-top: -3.75rem;
        &::after {
          height: 15.125rem;
        }
      }
    `};

  ${({ isMobileAlt }) =>
    isMobileAlt &&
    css`
      @media ${customMedia.maxPhone} {
        padding-bottom: 2rem;
        &::after {
          display: none;
        }
      }
    `};

  @media ${media.phone} {
    padding-bottom: 12.125rem;
    margin-top: 4rem;
    &::after {
      height: 8.875rem;
    }
  }
`

const StyledAboutTile = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const StyledAboutImg = styled.div`
  position: relative;
  padding-top: 72.3%;
  height: 0;

  .gatsby-image-wrapper {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media ${media.phone} {
    padding-top: 55%;
  }
`

const StyledAboutContent = styled.div``

const StyledAboutAction = styled.div`
  display: none;
  @media ${media.phone} {
    margin-top: 5.25rem;
    display: flex;
    justify-content: center;
  }
`

export {
  StyledAbout,
  StyledAboutList,
  StyledAboutItem,
  StyledAboutTile,
  StyledAboutContent,
  StyledAboutImg,
  StyledAboutAction,
}
