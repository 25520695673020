import React from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'

import { StyledAboutTile } from '../../styles'
import {
  StyledAboutTitle,
  StyledAboutDescription,
  StyledAboutNumber,
  StyledAboutNumberSubtitle,
} from './styles'
import Statistics from '../../../Statistics'
import { Heading8, Typo11, Typo12, Typo13, Typo14 } from '../../../Typography'

const SimpleSection = ({
  title,
  titleColor,
  description,
  count,
  afterfix,
  intervalDelay,
}) => (
  <StyledAboutTile>
    {title && (
      <StyledAboutTitle>
        <Heading8 fontWeight="bold" color={titleColor} as="h2">
          {ReactHtmlParser(title)}
        </Heading8>
      </StyledAboutTitle>
    )}
    {description && (
      <StyledAboutDescription>
        <Typo11 color="valhalla" fontWeight="bold">
          {ReactHtmlParser(description)}
        </Typo11>
      </StyledAboutDescription>
    )}
    {count && count.countValue && (
      <>
        <StyledAboutNumber>
          <Statistics
            value={count.countValue}
            color={count.countValueColor}
            {...{ afterfix, intervalDelay }}
          />
        </StyledAboutNumber>
        {count.countSubtitle && (
          <StyledAboutNumberSubtitle>
            {count.countSubtitleSmall ? (
              <Typo13
                as="h3"
                color={
                  count.countSubtitleColor
                    ? count.countSubtitleColor
                    : count.countValueColor
                }
                fontWeight="bold"
              >
                {ReactHtmlParser(count.countSubtitle)}
              </Typo13>
            ) : null}
            {count.countSubtitleExtraSmall ? (
              <Typo14
                as="h3"
                color={
                  count.countSubtitleColor
                    ? count.countSubtitleColor
                    : count.countValueColor
                }
                fontWeight="bold"
              >
                {ReactHtmlParser(count.countSubtitle)}
              </Typo14>
            ) : null}
            {!count.countSubtitleSmall && !count.countSubtitleExtraSmall ? (
              <Typo12
                as="h2"
                color={
                  count.countSubtitleColor
                    ? count.countSubtitleColor
                    : count.countValueColor
                }
                fontWeight="medium"
              >
                {ReactHtmlParser(count.countSubtitle)}
              </Typo12>
            ) : null}
          </StyledAboutNumberSubtitle>
        )}
      </>
    )}
  </StyledAboutTile>
)

export default SimpleSection

SimpleSection.propTypes = {
  title: PropTypes.string,
  titleColor: PropTypes.string,
  description: PropTypes.string,
  count: PropTypes.object,
  afterfix: PropTypes.string,
}
