import styled, { css } from 'styled-components'

import media from '../../../../common/MediaQueries'
import { transitionDelay } from '../../../../common/mixins'

export const StyledCirclesSectionColBox = styled.div`
  @media ${media.phone} {
    position: relative;
    display: flex;

    &::before,
    &::after {
      position: absolute;
      content: '';
      display: block;
      background-color: ${({ theme }) => theme.colors.valhalla};
      left: calc(50% - 1px);
      width: 0.125rem;
    }
    &::before {
      height: 9.375rem;
      top: -4rem;
    }
    &::after {
      height: 5.875rem;
      bottom: -3.25rem;
    }
  }
`

export const StyledCirclesSectionCol = styled.div`
  @media ${media.phone} {
    width: 50%;
    svg {
      font-size: 31rem;
    }
    &:nth-child(1) {
      display: flex;
      justify-content: flex-end;
      margin-top: -3rem;
    }
  }
`

export const StyledCirclesSectionIcon = styled.div`
  position: relative;
  ${transitionDelay()};

  svg {
    font-size: 13.375rem;
  }

  svg path {
    transition-property: fill;
    transition-duration: 250ms;
  }

  ${({ isVisible }) =>
    isVisible &&
    css`
      .circles-icons path {
        fill: ${({ theme }) => theme.colors.orange};
      }
    `};

  @media ${media.phone} {
    svg {
      font-size: 22rem;
    }
  }

  @media ${media.tablet} {
    svg {
      font-size: 31rem;
    }
  }
`

export const StyledCirclesSectionNumber = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const StyledCirclesSectionDesc = styled.div`
  margin-top: 2.5rem;
  max-width: 12.5rem;
  margin-left: auto;
  margin-right: auto;

  @media ${media.phone} {
    display: flex;
    height: 100%;
    align-items: center;
    margin-top: 0;
    margin-left: 3.375rem;
  }
`
