import React from 'react'

import { IconWrapper } from '../../common/mixins'

const ArrowRightIcon = () => (
  <IconWrapper>
    <svg
      width="44"
      height="52"
      viewBox="0 0 44 52"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="currentColor" strokeWidth="2" fill="none" fillRule="evenodd">
        <path d="M16.75 1l24.87 24.803-24.87 24.802" />
        <path strokeLinecap="square" d="M1.852 25.803h37.5" />
      </g>
    </svg>
  </IconWrapper>
)

export default ArrowRightIcon
