import styled from 'styled-components'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'

import media from '../../common/MediaQueries'
import { transition } from '../..//common/mixins'

const StyledDownload = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledDownloadContent = styled.div`
  @media ${media.phone} {
    display: flex;
    justify-content: space-between;
    max-width: 72rem;
    margin: 0 auto;
  }
`

const StyledDownloadSliderWrapper = styled.div`
  width: 100%;
  max-width: 66.375rem;
  padding: 0 0.75rem;

  .slick-next,
  .slick-prev {
    transform: translate(0);
    top: calc(50% - 5.1875rem + 1.5rem);
    ${transition};

    &:hover {
      opacity: 0.8;
    }
  }

  .slick-next svg,
  .slick-prev svg {
    font-size: 2.125rem;
    width: 0.4em;
    color: ${({theme}) => theme.colors.valhalla};
  }

  .slick-prev {
    left: -1.5rem;
  }

  .slick-next {
    right: -1.5rem;
    transform: rotate(180deg);
  }

  .slick-track {
    display: flex;
    height: 100%;
  }

  @media ${media.phone} {
    .slick-slide + .slick-slide {
      margin-left: 1.375rem;
    }
  }

  @media ${media.tablet} {
    .slick-prev {
      left: -3.5rem;
    }

    .slick-next {
      right: -3.5rem;
    }
  }
`

const StyledDownloadSlider = styled(Slider)``

const StyledDownloadBox = styled.a`
  display: block;
  ${transition}

  &:hover {
    opacity: 0.8;
  }
`

const StyledDownloadBoxMedia = styled.div`
  background-color: ${({ theme }) => theme.colors.white};

  .gatsby-image-wrapper div {
    padding-bottom: 78% !important;
  }

  @media ${media.phone} {
    .gatsby-image-wrapper {
      height: 16.625rem;
      max-width: 21.25rem;
      margin: 0 auto;
    }
  }
`

const StyledDownloadBoxTitle = styled.div`
  margin-top: 3.5rem;

  span {
    font-weight: ${({ theme }) => theme.fontWeights.light};
  }
`

const StyledDownloadHead = styled.div`
  margin-bottom: 4.25rem;

  @media ${media.phone} {
    margin-bottom: 6.25rem;
  }
`

const StyledDownloadListItem = styled.li``

export {
  StyledDownload,
  StyledDownloadContent,
  StyledDownloadHead,
  StyledDownloadListItem,
  StyledDownloadSliderWrapper,
  StyledDownloadSlider,
  StyledDownloadBox,
  StyledDownloadBoxMedia,
  StyledDownloadBoxTitle,
}
