import styled, { css } from 'styled-components'
import Img from 'gatsby-image/withIEPolyfill'

import media, { customMedia } from '../../common/MediaQueries'
import { transition } from '../../common/mixins'
import Inner from '../Inner'
import { Heading17, Typo8 } from '../Typography'
import { StyledNavigationLinkLabel } from '../Links/NavigationLink/styles'

export const StyledService = styled.div``

export const StyledServiceRow = styled.div`
  position: relative;
  width: 100%;
  max-width: 90rem;
  margin: 0 auto;

  color: white;

  @media ${media.tablet} {
    display: flex;
    position: relative;
  }
`

export const StyledServiceInner = styled(Inner)`
  @media ${media.tablet} {
    margin-left: 0;
    padding-left: 4rem;
    max-width: calc(27.5rem + 4rem + 1.75rem);
  }
`

export const StyledServiceMediaBox = styled.div`
  @media ${media.tablet} {
    width: 50%;
  }
`

export const StyledServiceMediaAction = styled.div``

export const StyledServicePlay = styled.div`
  position: absolute;
  bottom: 2.5rem;
  left: 0;
  padding: 0 1.25rem;
  width: 100%;
  ${transition}
  z-index: 3;

  @media ${media.tablet} {
    bottom: 50%;
    transform: translateY(50%);
  }
`

export const StyledServiceVideo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.black};
  z-index: 5;
`

export const StyledServiceMedia = styled.div`
  position: relative;
  display: block;
  cursor: pointer;

  &:hover {
    ${StyledNavigationLinkLabel} {
      text-decoration: underline;
    }
  }

  @media ${media.tablet} {
    height: 100%;
    max-height: 42.25rem;
    position: absolute;
    top: 0;
    left: 0;
    right: 50%;

    ${({ isVideoPlaying }) =>
      isVideoPlaying &&
      css`
        right: 0;
      `};
  }
`

export const StyledServiceImg = styled(Img)`
  height: 29.4375rem;

  @media ${media.tablet} {
    height: 100%;
    max-height: 42.25rem;
  }
`

export const StyledServiceContent = styled.div`
  margin-top: 3.5rem;
  @media ${media.tablet} {
    margin-top: 0;
    width: 50%;
  }
`

export const StyledServiceTitle = styled(Heading17)`
  margin-bottom: 3.125rem;

  -webkit-text-fill-color: ${({ theme }) => theme.colors.valhalla};
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: ${({ theme }) => theme.colors.white};

  @media ${media.tablet} {
    -webkit-text-stroke-width: 0.125rem;
  }
`

export const StyledServiceSubtitle = styled(Typo8)`
  margin-bottom: 2rem;
  @media ${media.tablet} {
    margin-bottom: 1.25rem;
    max-width: 21.25rem;
  }
`

export const StyledServiceDesc = styled(Typo8)``

export const StyledServiceMoreWrapper = styled.div`
  @media ${media.tablet} {
    width: 50%;
    padding-left: 4rem;
    margin-left: auto;
  }
`

export const StyledServiceMore = styled.a`
  display: block;
  margin-top: 3.75rem;
  @media ${media.tablet} {
    ${transition}
    margin-top: 3.125rem;
    max-width: 26rem;
  }
`

export const StyledServiceAction = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 3.375rem;
  @media ${media.tablet} {
    margin-top: 4.625rem;
  }
`

export const StyledServiceClose = styled.button`
  position: absolute;
  top: -2rem;
  right: 0.5rem;
  z-index: 1;
  cursor: pointer;
  ${transition}

  svg {
    font-size: 1.5rem;
  }

  &:hover {
    opacity: 0.8;
  }

  @media ${media.phone} {
    top: -2.5rem;
    right: 1.75rem;
  }

  @media ${customMedia.fullWidth} {
    right: 0;
  }
`
