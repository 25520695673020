import React from 'react'

import { IconWrapper } from '../../common/mixins'

const PeopleRightIcon = () => (
  <IconWrapper>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="196"
      height="143"
      viewBox="0 0 196 143"
    >
      <g
        stroke="currentColor"
        strokeWidth="2.5"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M127.751 92.09v13.888l-28.67 10.537C92.438 118.871 88 125.245 88 132.245V141h106v-8.755c0-7-4.642-13.374-11.286-15.73l-28.465-10.537V93.301" />
        <path d="M170 63.5c0 17.952-12.316 32.5-27.508 32.5C127.307 96 115 81.452 115 63.5c0-17.944 12.307-32.5 27.492-32.5C157.684 31 170 45.556 170 63.5zM35.373 54.903v11.382l-24.067 8.639C5.726 76.856 2 82.08 2 87.818V95h89v-7.182c0-5.737-3.888-10.962-9.469-12.894l-23.904-8.639V54" />
        <path d="M68 29.5C68 44.688 57.696 57 45 57c-12.706 0-23-12.312-23-27.5S32.294 2 45 2c12.696 0 23 12.312 23 27.5z" />
        <path d="M68 28.432c-.774.1-1.445-.012-2.235.144-7.9 1.632-12.972-1.425-17.322-9.576-2.609 5.356-10.758 9.576-17.462 9.576-3.295 0-6.138-.718-8.981-2.316" />
      </g>
    </svg>
  </IconWrapper>
)

export default PeopleRightIcon
