import styled, { css } from 'styled-components'
import { rgba } from 'polished'

import Container from '../Container'
import Inner from '../Inner'
import { transition } from '../../common/mixins'
import media from '../../common/MediaQueries'

const StyledValues = styled.div`
  text-align: center;
  overflow: hidden;
  padding-top: 5.125rem;
  padding-bottom: 3.125rem;
  background: ${({ theme }) => rgba(theme.colors.navyBlue2, 0.06)};

  @media ${media.phone} {
    padding-top: 4.75rem;
    padding-bottom: 6.75rem;
  }
`

const StyledValuesInner = styled(Inner)``

const StyledValuesHeader = styled.div`
  max-width: 42.5rem;
  margin: 0 auto 3rem auto;
`

const StyledValuesTitle = styled.div`
  margin-bottom: 2.5rem;

  @media ${media.phone} {
    margin-bottom: 2rem;
  }
`

const StyledValuesSecondTitle = styled.div`
  margin-bottom: 1rem;
`

const StyledValuesInfo = styled.div`
  span {
    &.desktop {
      display: none;
    }

    @media ${media.tablet} {
      &.mobile {
        display: none;
      }
      &.desktop {
        display: inline;
      }
    }
  }
`

const StyledValuesContent = styled.div`
  position: relative;

  @media ${media.tablet} {
    max-width: 35rem;
    margin: 0 auto;
  }

  @media ${media.desktop} {
    max-width: 100%;
  }
`

const StyledValuesTabDesc = styled(Container)`
  text-align: left;
  width: 100%;

  img {
    max-width: 4.375rem;
    margin-bottom: 1rem;
  }

  @media ${media.tablet} {
    position: absolute;
    max-width: 14rem;

    img {
      display: none;
    }
  }

  @media ${media.desktop} {
    max-width: 19.625rem;
  }
`

const StyledValuesIconPath = styled.path`
  cursor: pointer;
  ${transition({ timingFunction: 'ease' })}

  ${({ active }) =>
    active &&
    css`
      transform: scaleX(1.05) scaleY(1.05);
      transform-origin: center;
    `}


  &#financing {
    fill: #dae3cd;

    ${({ active }) =>
      active &&
      css`
        fill: #648c2e;
      `}
  }

  &#employees {
    fill: #dccdde;

    ${({ active }) =>
      active &&
      css`
        fill: #6e3075;
      `}
  }

  &#energy {
    fill: #f8d1c6;

    ${({ active }) =>
      active &&
      css`
        fill: #e03f10;
      `}
  }

  &#tax {
    fill: #ccebed;

    ${({ active }) =>
      active &&
      css`
        fill: #009ca7;
      `}
  }

  &#investment {
    fill: #a3bad3;

    ${({ active }) =>
      active &&
      css`
        fill: #003f84;
      `}
  }

  @media ${media.phone} {
    &:hover {
      transform: scaleX(1.05) scaleY(1.05);
      transform-origin: center;
    }

    &#financing {
      &:hover {
        fill: #648c2e;
      }
    }

    &#employees {
      &:hover {
        fill: #6e3075;
      }
    }

    &#energy {
      &:hover {
        fill: #e03f10;
      }
    }

    &#tax {
      &:hover {
        fill: #009ca7;
      }
    }

    &#investment {
      &:hover {
        fill: #003f84;
      }
    }
  }
`

const StyledValuesIcon = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 19rem;

  svg {
    font-size: 19rem;
  }

  @media ${media.phone} {
    max-width: 30rem;
    svg {
      font-size: 30rem;
    }
  }

  @media ${media.tablet} {
    max-width: 35rem;
    svg {
      font-size: 35rem;
    }
  }

  @media ${media.desktop} {
    max-width: 50rem;
    svg {
      font-size: 50rem;
    }
  }
`

const StyledValuesTab = styled.div`
  opacity: 0;
  visibility: hidden;
  max-height: 0;
  transition: opacity 270ms, visibility 270ms ease, max-height 0;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    transition: none;
  }

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      visibility: visible;
      max-height: 20rem;
    `}

    @media ${media.tablet} {
      ${transition}

      ${({ active }) =>
        active &&
        css`
          max-height: initial;
        `}

    }
`

const StyledValuesTabContent = styled.div`
  position: absolute;
  top: 9.5rem;
  left: 50%;
  transform: translate(-50%, -50%);

  @media ${media.phone} {
    top: 15rem;
  }

  @media ${media.tablet} {
    top: 50%;
  }
`

const StyledValuesIconImg = styled(Container)`
  position: absolute;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      visibility: visible;
    `}

  img {
    max-width: 2.5rem;
  }

  @media ${media.phone} {
    img {
      max-width: 4rem;
    }
  }

  @media ${media.tablet} {
    ${transition}

    img {
      max-width: 5rem;
    }
  }

  @media ${media.desktop} {
    img {
      max-width: 100%;
    }
  }
`

const StyledValuesValue = styled.p`
  font-size: 4rem;
  line-height: 1;
  font-weight: ${({ theme }) => theme.fontWeights.ultra};
  letter-spacing: -0.9px;
  color: ${({ theme }) => theme.colors.white};
  -webkit-text-fill-color: currentColor;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: ${({ theme }) => theme.colors.navyBlue2};

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    color: ${({ theme }) => theme.colors.navyBlue2};
  }

  @media ${media.phone} {
    font-size: 6rem;
    line-height: 1.25;
  }

  @media ${media.tablet} {
    font-size: 9rem;
  }
`

export {
  StyledValues,
  StyledValuesInner,
  StyledValuesHeader,
  StyledValuesTitle,
  StyledValuesSecondTitle,
  StyledValuesInfo,
  StyledValuesContent,
  StyledValuesTabDesc,
  StyledValuesIcon,
  StyledValuesIconPath,
  StyledValuesTab,
  StyledValuesTabContent,
  StyledValuesIconImg,
  StyledValuesValue,
}
