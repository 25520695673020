import React from 'react'

import { IconWrapper } from '../../common/mixins'

const CloseIcon = () => (
  <IconWrapper>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
    >
      <g stroke="currentColor" fill="none" fillRule="evenodd">
        <ellipse cx="17.476" cy="17.5" rx="16.976" ry="17" />
        <g strokeLinecap="round" strokeWidth="2" transform="translate(1.5, 0)">
          <path d="M10 11l14 14M23 11L10 24" />
        </g>
      </g>
    </svg>
  </IconWrapper>
)

export default CloseIcon
