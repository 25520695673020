import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledNavigationLink,
  StyledNavigationLinkLabel,
  StyledNavigationLinkIcon,
} from './styles'
import PlusIcon from '../../Icons/PlusIcon'
import { Typo10 } from '../../Typography'

const NavigationLink = ({ label, color, isAlignedStart }) => (
  <StyledNavigationLink {...{ color, isAlignedStart }}>
    <StyledNavigationLinkIcon>
      <PlusIcon />
    </StyledNavigationLinkIcon>
    <StyledNavigationLinkLabel>
      <Typo10 fontWeight="medium">{label}</Typo10>
    </StyledNavigationLinkLabel>
  </StyledNavigationLink>
)

export default NavigationLink

NavigationLink.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
  isAlignedStart: PropTypes.bool,
}
