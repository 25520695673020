import AnnualReportFile from '../../files/ses-2021-ar21-bookmark.pdf'
import StrategicReportFile from '../../files/ses-2021-strategic-report.pdf'
import GovernanceFile from '../../files/ses-2021-governance.pdf'
import FinancialStatementsFile from '../../files/ses-2021-financial-statements.pdf'
import AnnualPerformanceFile from '../../files/ses-2021-apr.pdf'

export const downloadsMock = [
  {
    title: 'Annual Performance Report',
    fileExtension: '(1.7MB)',
    url: AnnualPerformanceFile,
  },
  {
    title: 'Full Report',
    fileExtension: '(8.3MB)',
    url: AnnualReportFile,
  },
  {
    title: 'Strategic Report',
    fileExtension: '(5.4MB)',
    url: StrategicReportFile,
  },
  {
    title: 'Governance',
    fileExtension: '(2MB)',
    url: GovernanceFile,
  },
  {
    title: 'Financial Statements',
    fileExtension: '(972KB)',
    url: FinancialStatementsFile,
  },
]
