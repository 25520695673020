import React from 'react'
import { Element } from 'react-scroll'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Navigation from '../components/Navigation'
import Container from '../components/Container'
import Inner from '../components/Inner'
import Header from '../components/Header'
import About from '../components/About'
import Values from '../components/Values'
import Service from '../components/Service'
import Aims from '../components/Aims'
import DownloadLink from '../components/Links/DownloadLink'
import Downloads from '../components/Downloads'
import Tools from '../components/Tools'
import Heading from '../components/Heading'
import Hero from '../components/Hero'
import QA from '../components/QA'
import { URL_PATHS } from '../constants/urlPaths'
import OurAimsFile from '../files/ses-2021-our-performance.pdf'
import theme from '../themes/theme'
import { Heading14 } from '../components/Typography'

const IndexPage = () => (
  <Layout>
    <SEO />
    <Header />
    <main>
      <Container mt="5.4375rem" display={['block', 'none']}>
        <Inner>
          <Tools />
        </Inner>
      </Container>

      <Container>
        <Hero />
      </Container>

      <Container mt={["5rem", "", "7.5rem"]}>
        <Inner>
          <Container maxWidth="78.75rem" margin="0 auto">
            <Heading14 textAlign="center" color="valhalla" fontWeight="bold">
              With the support of our customers, our people, our suppliers and our
              investors we have turned a challenging time into a year that we’re
              proud of. As key workers, we have continued treating and
              distributing millions of litres of water 24/7 to homes, schools,
              hospitals and businesses – a vital part of fighting COVID-19. Our
              intent was simple – no one needed to worry about their water supply
              and we worked tirelessly to make sure this was always the case.
            </Heading14>
          </Container>
        </Inner>
      </Container>

      <Element name={URL_PATHS.REPORT}>
        <Navigation staticNav={true} />
      </Element>

      <Container
        pt={['5rem', '4rem']}
        pb={['0', '6rem']}
        textAlign="center"
        overflow="hidden"
      >
        <Element name={URL_PATHS.ABOUT_US}>
          <Inner>
            <Container maxWidth="35rem" ml="auto" mr="auto">
              <Heading title="About us" color={theme.colors.valhalla} />
            </Container>
            <Container pt={['6.5rem', '', '7.875rem']}>
              <About />
            </Container>
          </Inner>
        </Element>
      </Container>

      <Container>
        <Element name={URL_PATHS.VALUES}>
          <Container>
            <Values />
          </Container>
        </Element>
      </Container>

      <Container
        bg="valhalla"
        pt={['5rem', '6.625rem']}
        pb={['4.375rem', '9.375rem']}
      >
        <Inner>
          <Element name={URL_PATHS.QA}>
            <QA />
          </Element>
        </Inner>
      </Container>

      <Element name={URL_PATHS.AIMS}>
        <Container pt={['6.75rem', '', '8.5rem']} overflow="hidden">
          <Container textAlign="center">
            <Inner>
              <Heading
                title="Our performance this year"
                color={theme.colors.valhalla}
              />
            </Inner>
          </Container>
          <Container
            bg="lightestBlue"
            pt={['1.875rem', '6.5rem']}
            pb={['4.75rem', '5rem']}
          >
            <Inner>
              <Aims />
              <Container
                display="flex"
                justifyContent="center"
                mt={['1.75rem', '', '7rem']}
              >
                <DownloadLink
                  url={OurAimsFile}
                  label="Download Our Performance"
                />
              </Container>
            </Inner>
          </Container>
        </Container>
      </Element>

      <Element name={URL_PATHS.PUBLIC_INTEREST}>
        <Container
          pt="3.75rem"
          pb={['3.75rem', '', '7.5rem']}
          overflow="hidden"
          bg="valhalla"
        >
          <Container mb={['1.5rem', '4.25rem']}>
            <Inner>
              <Heading
                title="Working in the public interest"
                subtitle="Doing more for our communities"
                color="white"
              />
            </Inner>
          </Container>
          <Container>
            <Service />
          </Container>
        </Container>
      </Element>

      <Element name={URL_PATHS.DOWNLOADS}>
        <Container
          bg="lightestBlue"
          pt={['3.375rem', '5.875rem']}
          pb={['5rem', '9.375rem']}
        >
          <Inner>
            <Downloads />
          </Inner>
        </Container>
      </Element>
    </main>
  </Layout>
)

export default IndexPage
