import React from 'react'

import { IconWrapper } from '../../common/mixins'

const ArrowSliderIcon = () => (
  <IconWrapper>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="35"
      viewBox="0 0 14 35"
    >
      <g stroke="currentColor" fill="none" fillRule="evenodd" strokeLinecap="square">
        <path d="M12.647 1.5l-11.294 16M12.647 33.5l-11.294-16" />
      </g>
    </svg>
  </IconWrapper>
)

export default ArrowSliderIcon
