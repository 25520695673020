import React from 'react'

import { IconWrapper } from '../../common/mixins'

const ArrowLeftIcon = () => (
  <IconWrapper>
    <svg width="44" height="52" xmlns="http://www.w3.org/2000/svg">
      <g stroke="currentColor" strokeWidth="2" fill="none" fillRule="evenodd">
        <path d="M27.25 1L2.38 25.803l24.87 24.802" />
        <path strokeLinecap="square" d="M42.148 25.803h-37.5" />
      </g>
    </svg>
  </IconWrapper>
)

export default ArrowLeftIcon
