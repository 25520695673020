import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import {
  StyledService,
  StyledServiceInner,
  StyledServiceMedia,
  StyledServiceMediaBox,
  StyledServiceContent,
  StyledServiceTitle,
  StyledServiceSubtitle,
  StyledServiceDesc,
  StyledServiceMore,
  StyledServiceAction,
  StyledServiceRow,
  StyledServiceImg,
  StyledServicePlay,
  StyledServiceMoreWrapper,
  StyledServiceVideo,
  StyledServiceClose,
} from './styles'
import DownloadLink from '../Links/DownloadLink'
import NavigationLink from '../Links/NavigationLink'
import Inner from '../Inner'
import CloseIconBold from '../Icons/CloseIconBold'
import Video from '../Video'
import WorkingInPublicInterestFile from '../../files/ses-2021-working-in-the-public-interest.pdf'

const Service = () => {
  const image = useStaticQuery(
    graphql`
      query {
        serviceImage: file(relativePath: { eq: "pic-report-4.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 350, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  )

  const [isVideoPlaying, setVideoPlaying] = useState(false)

  const handleClick = (id) => {
    setVideoPlaying(!isVideoPlaying)
  }

  return (
    <StyledService>
      <StyledServiceRow>
        {isVideoPlaying && (
          <StyledServiceClose onClick={handleClick}>
            <CloseIconBold />
          </StyledServiceClose>
        )}
        <StyledServiceMediaBox>
          <StyledServiceMedia
            title="watch the full video"
            onClick={handleClick}
            {...{ isVideoPlaying }}
          >
            <StyledServiceImg
              fluid={image.serviceImage.childImageSharp.fluid}
            />
            <StyledServicePlay>
              <NavigationLink label="Watch the full video" color="white" />
            </StyledServicePlay>
            {isVideoPlaying && (
              <StyledServiceVideo>
                <Video
                  altText="Video SES Water"
                  // videoUrl="https://vimeo.com/557974912"
                  videoUrl="https://youtu.be/HySPfbilHh4"
                />
              </StyledServiceVideo>
            )}
          </StyledServiceMedia>
        </StyledServiceMediaBox>
        <StyledServiceContent>
          <StyledServiceInner>
            <StyledServiceTitle color="white" fontWeight="ultra">
              A spotlight on...
            </StyledServiceTitle>
            <StyledServiceSubtitle
              fontSize={['1rem', '1.5625rem']}
              lineHeight={['1.25', '1.04']}
              letterSpacing={['-0.08px', '-0.13px']}
              fontWeight="medium"
              color="white"
            >
              Inspiring everyone to value our most precious resource
            </StyledServiceSubtitle>
            <StyledServiceDesc
              fontSize={['1rem', '1.25rem']}
              lineHeight={['1.25', '1.1']}
              letterSpacing={['-0.08px', '-0.1px']}
              fontWeight="book"
              color="white"
            >
              Last year we opened ‘Flow Zone’, our brand new, state-of-the-art
              education centre at Bough Beech Reservoir and Water Treatment
              Works, near Edenbridge in Kent.
              <br />
              <br />
              A sensory experience involving light and sound, the new centre
              provides a number of interactive activities and experiences for
              students and adults to immerse themselves in learning about the
              water cycle, water usage and the importance of saving water.
              <br />
              <br />
              The centre is a key part of our community engagement, and will
              allow us to continue inspiring future generations with our
              established education programme, which has been running for more
              than 20 years.
              <br />
              <br />
              While the pandemic meant we were not able to host visits for most
              of the last year, we are very pleased to now be welcoming back
              groups to the centre, and seeing the success of such an important
              investment.
            </StyledServiceDesc>
          </StyledServiceInner>
        </StyledServiceContent>
      </StyledServiceRow>
      <Inner>
        <StyledServiceMoreWrapper>
          <StyledServiceMore
            href={WorkingInPublicInterestFile}
            target="_blank"
            rel="noopener noreferrer"
          >
            <NavigationLink
              label="Read more about what we do in our communities in the Working in the public interest download"
              color="white"
              isAlignedStart
            />
          </StyledServiceMore>
        </StyledServiceMoreWrapper>
        <StyledServiceAction>
          <DownloadLink
            url={WorkingInPublicInterestFile}
            label="Download Working <br /> In The Public Interest"
          />
        </StyledServiceAction>
      </Inner>
    </StyledService>
  )
}

export default Service
