import React, { useState, useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import {
  StyledAim,
  StyledAimTeaserList,
  StyledAimTeaserItem,
  StyledAimDetailsWrapper,
  StyledAimDetailsSlider,
  StyledAimDetailsItem,
  StyledAimDetailsClose,
  StyledAimDetails,
  StyledAimDetailsContentBox,
  StyledAimDetailsContent,
} from './styles'
import { aims } from './mock'
import AimTeaser from './AimTeaser'
import AimDetails from './AimDetails'
import { PrevButton, NextButton } from '../SliderNavigation'
import CloseIconBold from '../Icons/CloseIconBold'

const sliderDetailsOptions = {
  arrows: true,
  prevArrow: <PrevButton />,
  nextArrow: <NextButton />,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  // responsive: [
  //   {
  //     breakpoint: 767,
  //     settings: {
  //       unslick: true,
  //     },
  //   },
  // ],
}

const Aims = () => {
  const {
    imagesBig: { edges: aimBig },
  } = useStaticQuery(
    graphql`
      query {
        imagesBig: allImageSharp(
          filter: { original: { src: { regex: "/aim-big/" } } }
          sort: { fields: original___src }
        ) {
          edges {
            node {
              id
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    `,
  )

  const sliderDetails = useRef('')
  const [isDetailsActive, setActiveDetails] = useState(false)

  const aimImages = aimBig.map(({ node: { id, fluid } }) => {
    return { idBigImage: id, fluidBigImage: fluid }
  })

  const aimDetailsArray = new Array(aimImages.length).fill('').map((_, i) => {
    return { ...aimImages[i], ...aims[i] }
  })

  const handleClick = (id) => {
    sliderDetails.current.slickGoTo(id, true)
    setActiveDetails(!isDetailsActive)
    const body = document.getElementsByTagName('body')
    body[0].classList.toggle('is-hidden')
  }

  return (
    <StyledAim>

      <StyledAimTeaserList isDetailsActive={isDetailsActive}>
        {aimDetailsArray.map(({ id, ...rest }) => (
          <StyledAimTeaserItem key={id} onClick={() => handleClick(id)}>
            <AimTeaser {...rest} />
          </StyledAimTeaserItem>
        ))}
      </StyledAimTeaserList>

      <StyledAimDetailsWrapper isDetailsActive={isDetailsActive}>
        <StyledAimDetails>
          <StyledAimDetailsContentBox>
            <StyledAimDetailsContent>
              <StyledAimDetailsClose type="button" onClick={handleClick}>
                <CloseIconBold />
              </StyledAimDetailsClose>
              <StyledAimDetailsSlider
                ref={sliderDetails}
                {...sliderDetailsOptions}
              >
                {aimDetailsArray.map(({ id, ...rest }) => (
                  <StyledAimDetailsItem key={id}>
                    <AimDetails id={id} {...rest} />
                  </StyledAimDetailsItem>
                ))}
              </StyledAimDetailsSlider>
            </StyledAimDetailsContent>
          </StyledAimDetailsContentBox>
        </StyledAimDetails>
      </StyledAimDetailsWrapper>
    </StyledAim>
  )
}

export default Aims
