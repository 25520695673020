import React from 'react'

import { IconWrapper } from '../../common/mixins'

const PeopleLeftIcon = () => (
  <IconWrapper>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="185"
      height="143"
      viewBox="0 0 185 143"
    >
      <g
        stroke="currentColor"
        strokeWidth="2.5"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M47.747 85.255v15.825l-32.99 12.009C7.107 115.776 2 123.039 2 131.015V141h122v-9.985c0-7.976-5.33-15.24-12.98-17.926l-32.767-12.01V84" />
        <path d="M93 51c0 20.987-14.112 38-31.5 38C44.098 89 30 71.987 30 51s14.098-38 31.5-38C78.888 13 93 30.013 93 51z" />
        <path d="M93 49.261c-1.06.13-1.979-.014-3.06.188C79.12 51.57 72.172 47.596 66.215 37 62.642 43.963 51.482 49.45 42.3 49.45c-4.513 0-8.407-.934-12.3-3.012M126.75 53.915v11.667l-24.341 8.85C96.768 76.413 93 81.767 93 87.646V95h90v-7.355c0-5.879-3.941-11.234-9.582-13.212l-24.169-8.851V53" />
        <path d="M162 29.5c0 15.19-10.3 27.5-23.007 27.5C126.293 57 116 44.69 116 29.5 116 14.316 126.293 2 138.993 2 151.7 2 162 14.316 162 29.5z" />
        <path d="M126.75 53.915v11.667l-24.341 8.85C96.768 76.413 93 81.767 93 87.646V95h90v-7.355c0-5.879-3.941-11.234-9.582-13.212l-24.169-8.851V53" />
        <path d="M162 29.5c0 15.19-10.3 27.5-23.007 27.5C126.293 57 116 44.69 116 29.5 116 14.316 126.293 2 138.993 2 151.7 2 162 14.316 162 29.5z" />
      </g>
    </svg>
  </IconWrapper>
)

export default PeopleLeftIcon
