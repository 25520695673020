import { URL_PATHS } from './urlPaths'

export default [
  {
    label: 'About us',
    action: 'read more',
    to: URL_PATHS.ABOUT_US,
  },
  {
    label: 'Q&A with <br /> Ian Cain,CEO',
    action: 'Listen to our podcast',
    to: URL_PATHS.QA,
  },
  {
    label: `Our <br /> performance`,
    action: 'read more',
    to: URL_PATHS.AIMS,
  },
  {
    label: 'Working in the <br /> public interest',
    action: 'read more',
    to: URL_PATHS.PUBLIC_INTEREST,
  },
]
