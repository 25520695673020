import React from 'react'

import { IconWrapper } from '../../common/mixins'

const PlusIcon = () => (
  <IconWrapper>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
    >
      <g stroke="currentColor" strokeWidth="2" fill="none" fillRule="evenodd">
        <circle cx="11" cy="11" r="10" />
        <g strokeLinecap="round">
          <path d="M11 6.2v9.6M15.333 11H6" />
        </g>
      </g>
    </svg>
  </IconWrapper>
)

export default PlusIcon
