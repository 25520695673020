import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'

import { StyledAbout, StyledAboutList, StyledAboutItem, StyledAboutAction } from './styles'
import { aboutDetails } from './mock'
import aboutUsFile from '../../files/ses-2021-about-us.pdf'

import SimpleSection from './AboutSections/SimpleSection'
import MapSection from './AboutSections/MapSection'
import PeopleSection from './AboutSections/PeopleSection'
import ProgressSection from './AboutSections/ProgressSection'
import DropSection from './AboutSections/DropSection'
import CirclesSection from './AboutSections/CirclesSection'
import ProgressValueSection from './AboutSections/ProgressValueSection'
import DownloadLink from '../Links/DownloadLink'

const About = () => {
  const {
    images: { edges: about },
  } = useStaticQuery(
    graphql`
      query {
        images: allImageSharp(
          filter: { original: { src: { regex: "/about/" } } }
          sort: { fields: original___src }
        ) {
          edges {
            node {
              id
              fluid(maxWidth: 446, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    `,
  )

  const aboutImages = about.map(({ node: { id, fluid } }) => {
    return { id, fluid }
  })

  return (
    <StyledAbout>
      <StyledAboutList>
        <Fade cascade delay={400} duration={500}>
          <StyledAboutItem>
            <SimpleSection
              title={aboutDetails[0].title}
              titleColor={aboutDetails[0].titleColor}
              description={aboutDetails[0].description}
              count={aboutDetails[0].count}
            />
          </StyledAboutItem>

          <StyledAboutItem>
            <SimpleSection
              title={aboutDetails[1].title}
              titleColor={aboutDetails[1].titleColor}
              description={aboutDetails[1].description}
              count={aboutDetails[1].count}
            />
          </StyledAboutItem>

          <StyledAboutItem isMap={true}>
            <MapSection
              description={aboutDetails[2].description}
              image={aboutImages[0].fluid}
            />
          </StyledAboutItem>

          <StyledAboutItem>
            <SimpleSection
              title={aboutDetails[3].title}
              titleColor={aboutDetails[3].titleColor}
              description={aboutDetails[3].description}
              count={aboutDetails[3].count}
            />
          </StyledAboutItem>

          <StyledAboutItem isMobileAlt>
            <PeopleSection
              title={aboutDetails[4].title}
              titleColor={aboutDetails[4].titleColor}
              description={aboutDetails[4].description}
              count={aboutDetails[4].count}
            />
          </StyledAboutItem>

          <StyledAboutItem isMobileAlt>
            <ProgressSection count={aboutDetails[5].count} />
          </StyledAboutItem>

          <StyledAboutItem>
            <ProgressValueSection
              topData={aboutDetails[8].topData}
              bottomData={aboutDetails[8].bottomData}
            />
          </StyledAboutItem>

          <StyledAboutItem>
            <DropSection count={aboutDetails[6].count} afterfix=" million" />
          </StyledAboutItem>

          <StyledAboutItem>
            <DropSection count={aboutDetails[7].count} afterfix="%" isAlt />
          </StyledAboutItem>

          <StyledAboutItem>
            <CirclesSection count={aboutDetails[9].count} />
          </StyledAboutItem>
        </Fade>
      </StyledAboutList>
      <StyledAboutAction>
        <DownloadLink
          url={aboutUsFile}
          label="Download About Us"
        />
      </StyledAboutAction>
    </StyledAbout>
  )
}

export default About
