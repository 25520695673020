import styled, { css } from 'styled-components'

import media from '../../../../common/MediaQueries'
import { transition } from '../../../../common/mixins'

export const StyledSimpleSectionPeople = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  @media ${media.phone} {
    margin-top: 5.125rem;
  }
`

export const StyledSimpleSectionPeopleCol = styled.div`
  display: none;
  align-items: center;
  justify-content: center;

  svg {
    ${transition}
    color: ${({ theme }) => theme.colors.orange};
    font-size: 5.75rem;
    width: 1.38em;
  }

  ${({ isMain }) =>
    isMain &&
    css`
      ${transition}
      display: flex;
      height: 10.75rem;
      width: 10.75rem;
      border-radius: 50%;
      border: 0.25rem solid ${({ theme }) => theme.colors.orange};
      @media ${media.phone} {
        height: 18.125rem;
        width: 18.125rem;
      }
    `};

  ${({ isVisible }) =>
    isVisible &&
    css`
      background-color: ${({ theme }) => theme.colors.orange};

      svg {
        color: ${({ theme }) => theme.colors.white};
      }
    `};

  @media ${media.phone} {
    display: flex;

    &:not(:first-child) {
      margin-left: 1rem;
    }

    svg {
      font-size: 8.875rem;
    }
  }
`
