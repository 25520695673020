import theme from '../../../../themes/theme'

export const circularBarConfig = {
  pathColor: theme.colors.crete,
  trailColor: theme.colors.valhalla,
}

export const circularBarConfigSecond = {
  pathColor: theme.colors.valhalla,
  trailColor: 'transparent',
  rotation: 0.03,
}
